import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, SelectField } from "../../FormFields";
import { City, Country, State } from "country-state-city";
import { useFormikContext } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const countries = Country.getAllCountries().map((country) => ({
  value: country.isoCode,
  label: `${country.name} - ${country.isoCode}`,
}));

const updatedStates = (countryId) =>
  State.getStatesOfCountry(countryId).map((statedata) => ({
    label: statedata.name,
    value: statedata.isoCode,
  }));

const updatedCity = (countryCode, cityCode) =>
  City.getCitiesOfState(countryCode, cityCode).map((citydata) => ({
    label: citydata.name,
    value: citydata.name,
  }));

const qualifications = [
  {
    value: 50,
    label: "Illiterate",
  },
  {
    value: 51,
    label: "Literate but Class 2 or lower",
  },
  {
    value: 52,
    label: "Class 2 or higher but Class 5 or lower",
  },
  {
    value: 53,
    label: "Class 6 or higher but Class 9 or lower",
  },
  {
    value: 54,
    label: "Class 10 or higher but Class 11 or lower",
  },
  {
    value: 55,
    label: "Class 12",
  },
  {
    value: 56,
    label: "Completed Vocational Certificate",
  },
  {
    value: 57,
    label: "Completed Diploma",
  },
  {
    value: 58,
    label: "Completed Non-professional Bachelor's degree (eg. BA, BSc, etc.)",
  },
  {
    value: 59,
    label: "Completed Professional Bachelor's degree (eg. BTech, MBBS, etc.)",
  },
  {
    value: 60,
    label: "Completed Professional Certification (CA, CS, etc.)",
  },
  {
    value: 61,
    label: "Completed Non-professional Master's degree (eg. MA, MSc, etc.)",
  },
  {
    value: 62,
    label: "Completed Professional Master's degree (eg. MTech, etc.)",
  },
  {
    value: 63,
    label: "Completed Doctorate",
  },
  {
    value: 70,
    label: "Other Qualification",
  },
];

const occupations = [
  {
    value: 10,
    label: "Full-time Student",
  },
  {
    value: 11,
    label: "Not Employed (such as housewife)",
  },
  {
    value: 12,
    label: "Unemployed (actively seeking employment)",
  },
  {
    value: 13,
    label: "Retired",
  },
  {
    value: 14,
    label: "Agriculture, Farming and Forestry",
  },
  {
    value: 15,
    label: "Architecture and Engineering",
  },
  {
    value: 16,
    label: "Arts, Design, Entertainment, Sports, and Media",
  },
  {
    value: 17,
    label: "Building and Grounds Cleaning and Maintenance",
  },
  {
    value: 18,
    label: "Business and Financial Operations",
  },
  {
    value: 19,
    label: "Civil Services (Administrative Services)",
  },
  {
    value: 20,
    label: "Community and Social Service",
  },
  {
    value: 21,
    label: "Computer, Software and Mathematical, etc.",
  },
  {
    value: 22,
    label: "Construction and Extraction",
  },
  {
    value: 23,
    label: "Education, Training, and Library",
  },
  {
    value: 24,
    label: "Food Preparation and Serving Related",
  },
  {
    value: 25,
    label: "Healthcare Practitioners and Technical",
  },
  {
    value: 26,
    label: "Healthcare Support",
  },
  {
    value: 27,
    label: "Installation, Maintenance, and Repair",
  },
  {
    value: 28,
    label: "Legal",
  },
  {
    value: 29,
    label: "Life, Physical, and Social Science",
  },
  {
    value: 30,
    label: "Management",
  },
  {
    value: 31,
    label: "Military Specific",
  },
  {
    value: 32,
    label: "Office and Administrative Support",
  },
  {
    value: 33,
    label: "Personal Care and Service",
  },
  {
    value: 34,
    label: "Production",
  },
  {
    value: 35,
    label: "Protective Service (Police and related)",
  },
  {
    value: 36,
    label: "Sales and Related",
  },
  {
    value: 37,
    label: "Transportation and Material Moving",
  },
  {
    value: 40,
    label: "Other Occupations",
  },
];

const nationalities = [
  {
    value: "IN",
    label: "Indian",
  },
  {
    value: "DC",
    label: "Dual Citizen: India + Other",
  },
  {
    value: "FC",
    label: "Other Nationality",
  },
];

const castes = [
  {
    value: "GN",
    label: "GN",
  },
  {
    value: "BC",
    label: "BC",
  },
  {
    value: "SC",
    label: "SC",
  },
  {
    value: "ST",
    label: "ST",
  },
];

export default function FourthForm(props) {
  const { values: formValues, setFieldValue } = useFormikContext();
  const {
    formField: {
      email,
      caste,
      qualification,
      occupation,
      nationality,
      address1,
      address2,
      city,
      zipcode,
      country,
      region,
    },
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Applicant Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField name={address1.name} label={address1.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={address2.name} label={address2.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={zipcode.name} label={zipcode.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField name={country.name} label={country.label} data={countries} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={region.name}
            label={region.label}
            data={updatedStates(formValues.country)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={city.name}
            label={city.label}
            data={updatedCity(formValues.country, formValues.region)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={email.name} label={email.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="p">Phone Number</Typography>
          <PhoneInput
            country="in"
            value={formValues.phone}
            onChange={(p) => setFieldValue("phone", p)}
            inputProps={{
              name: "phonex",
              required: true,
              autoFocus: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField name={caste.name} label={caste.label} data={castes} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={qualification.name}
            label={qualification.label}
            data={qualifications}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={occupation.name}
            label={occupation.label}
            data={occupations}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={nationality.name}
            label={nationality.label}
            data={nationalities}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
