import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, SelectField } from "../../FormFields";
import { useFormikContext } from "formik";
import axios from "axios";

const title_dataf = [
  {
    value: "PB",
    label: "PB",
  },
  {
    value: "MR",
    label: "MR",
  },
];

const title_datam = [
  {
    value: "PBN",
    label: "PBN",
  },
  {
    value: "MS",
    label: "MS",
  },
];
const title_datas = [
  {
    value: "",
    label: "--",
  },
  {
    value: "PB",
    label: "PB",
  },
  {
    value: "PBN",
    label: "PBN",
  },
  {
    value: "MR",
    label: "MR",
  },
  {
    value: "MS",
    label: "MS",
  },
];
export default function ThirdForm(props) {
  let userside = JSON.parse(localStorage.getItem("user"));
  let AccessToken = userside ? userside["api"]["AccessToken"] : "Authorization";
  const [inputValue, setInputValue] = useState(false);
  const [inputValuem, setInputValuem] = useState(false);
  const [inputValues, setInputValues] = useState(false);
  const {
    formField: {
      mother_uid,
      father_uid,
      spouse_uid,
      titlef,
      fatherName,
      titlem,
      motherName,
      titles,
      spouse_name,
    },
  } = props;
  const { setFieldValue } = useFormikContext(undefined);
  const handleFatherUidChange = async (event) => {
    const newFatherUidValue = event.target.value;
    setFieldValue("fatherName", "");
    if (newFatherUidValue.length > 10) {
      setInputValue(false);
      // Perform any logic to fetch or compute fatherName based on father_uid
      // For now, let's assume fatherName can be directly derived from father_uid
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_USER_DATA_COGNITO}/${newFatherUidValue}`,
          {
            headers: {
              Authorization: AccessToken,
            },
          }
        );
        const responseBody = response?.data; // Axios automatically parses JSON response
        if (responseBody && responseBody.length > 0) {
          const firstName = responseBody[0].first_name;
          const middleName = responseBody[0].middle_name ? ` ${responseBody[0].middle_name}` : "";
          const lastName = responseBody[0].last_name ? ` ${responseBody[0].last_name}` : "";
          setFieldValue("fatherName", `${firstName}${middleName}${lastName}`);
          setFieldValue("titlef", "PB");
          setInputValue(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const handleMotherUidChange = async (event) => {
    const newMotherUidValue = event.target.value;
    setFieldValue("motherName", "");
    if (newMotherUidValue.length > 10) {
      setInputValuem(false);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_USER_DATA_COGNITO}/${newMotherUidValue}`,
          {
            headers: {
              Authorization: AccessToken,
            },
          }
        );
        const responseBody = response?.data; // Axios automatically parses JSON response
        if (responseBody && responseBody.length > 0) {
          const firstName = responseBody[0].first_name;
          const middleName = responseBody[0].middle_name ? ` ${responseBody[0].middle_name}` : "";
          const lastName = responseBody[0].last_name ? ` ${responseBody[0].last_name}` : "";
          setFieldValue("motherName", `${firstName}${middleName}${lastName}`);
          setFieldValue("titlem", "PBN");
          setInputValuem(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const handleSpouseUidChange = async (event) => {
    const newSpouseUidValue = event.target.value;
    setFieldValue("spouse_name", "");
    if (newSpouseUidValue.length > 10) {
      setInputValues(false);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_USER_DATA_COGNITO}/${newSpouseUidValue}`,
          {
            headers: {
              Authorization: AccessToken,
            },
          }
        );
        const responseBody = response?.data; // Axios automatically parses JSON response
        if (responseBody && responseBody.length > 0) {
          const firstName = responseBody[0].first_name;
          const middleName = responseBody[0].middle_name ? ` ${responseBody[0].middle_name}` : "";
          const lastName = responseBody[0].last_name ? ` ${responseBody[0].last_name}` : "";
          setFieldValue("spouse_name", `${firstName}${middleName}${lastName}`);
          setFieldValue("titles", responseBody[0].title);
          setInputValues(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom></Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <InputField
            name={father_uid.name}
            label={father_uid.label}
            fullWidth
            onKeyUp={handleFatherUidChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={titlef.name} label={titlef.label} data={title_dataf} fullWidth />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputField
            name={fatherName.name}
            label={fatherName.label}
            fullWidth
            disabled={inputValue}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputField
            name={mother_uid.name}
            label={mother_uid.label}
            fullWidth
            onKeyUp={handleMotherUidChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={titlem.name} label={titlem.label} data={title_datam} fullWidth />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputField
            name={motherName.name}
            label={motherName.label}
            fullWidth
            disabled={inputValuem}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputField
            name={spouse_uid.name}
            label={spouse_uid.label}
            fullWidth
            onKeyUp={handleSpouseUidChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SelectField name={titles.name} label={titles.label} data={title_datas} fullWidth />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputField
            name={spouse_name.name}
            label={spouse_name.label}
            fullWidth
            disabled={inputValues}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
