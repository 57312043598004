import React from "react";
import { useFormikContext } from "formik";
import { Typography, Grid } from "@material-ui/core";

export default function ReviewOrder() {
  const { values: formValues } = useFormikContext();
  const {
    firstName,
    lastName,
    middleName,
    email,
    phone,
    qualification,
    occupation,
    designation,
    bloodGroup,
    nationality,
    dob,
    father_uid,
    mother_uid,
    spouse_uid,
    fatherName,
    motherName,
    spouse_name,
    address1,
    address2,
    city,
    zipcode,
    country,
    region,
    caste,
    organization,
    photo,
  } = formValues;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Review
      </Typography>
      <div className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-column align-items-center text-center">
                  <img
                    src={`${process.env.REACT_APP_BACKEND_SERVER_URL}${photo}`}
                    alt="Admin"
                    className="rounded-circle"
                    width="150"
                  />
                  <div className="mt-3 new walues">
                    <h4>{`${firstName} ${middleName} ${lastName}`}</h4>
                    <p className="text-secondary mb-1">{`${email}`}</p>
                    <p className="text-muted font-size-sm">{`${phone}`}</p>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div className="card mb-3">
              <Grid container spacing={3} stype={{ padding: "20px" }}>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Full Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${firstName} ${middleName} ${lastName}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">DOB</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${dob.split("T")[0]}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Father Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${fatherName}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Father UID</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${father_uid}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Mother Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${motherName}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Mother UID</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${mother_uid}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Spouses Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${spouse_name}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Spouses UID</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${spouse_uid}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Address</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${address1}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Address Line 2</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${address2}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">City</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${city}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">State</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${region}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Country</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${country}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Zip Code/Pin Code</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${zipcode}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Phone/Mobile</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${phone}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Organization</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${organization}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Occupation</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${occupation}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Qualification</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${qualification}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Designation</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${designation}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Nationality</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${nationality}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Caste</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${caste}`}</div>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">House</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${house}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Vehicle</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${vehicle}`}</div>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Blood Group</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${bloodGroup}`}</div>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Working Hours</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${workingHours}`}</div>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Photo</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${photo}`}</div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
