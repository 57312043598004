/**
=========================================================
* Dayalbagh New System React - v2.0.0
=========================================================

* Product Page: https://www.hurekatek.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Hureka Technologies Inc. (https://www.hurekatek.com)

Coded by www.hurekatek.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
//import Icon from "@mui/material/Icon";

// Dayalbagh New System React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Dayalbagh New System React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import JigyasuApi from "api/jigyasu";
import React, { useState, useEffect } from "react";
import { useAuth } from "auth-context/auth.context";
import { useHistory } from "react-router-dom";
//import rocketWhite from "assets/images/illustrations/rocket-white.png";

function Dashboard() {
  //const classes = styles();
  const [countdata, setData] = useState({});
  let { user } = useAuth();
  if (user.role != "BBRANCHSEC") {
    const history = useHistory();
    if (user.role == "DATAVIEWER") {
      history.push("/vr/dashboard");
    } else {
      history.push("/uv/dashboard");
    }
  }
  useEffect(async () => {
    let responce = await JigyasuApi.GetJigyasuDataCount();
    setData(responce?.data);
  }, []);
  let urlp = "";
  let urla = "";
  let urlr = "";
  urlp = "/uv/jigyasu/inprocess";
  urla = "/uv/jigyasu/approved";
  urlr = "/uv/jigyasu/rejected";
  let urladd = "/jigyasu/form";
  let urld = "/uv/jigyasu/draft";

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Card>
                <SuiBox p={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} xl={12}>
                      <SuiTypography variant="h2" mb={2} fontWeight="bold" gutterBottom>
                        Jigyasu Registration
                      </SuiTypography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      xl={12}
                      p={3}
                      style={{ color: "white", backgroundColor: "#fef8dd" }}
                    >
                      <Grid item xs={12} sm={2} xl={2}>
                        <Link href={urladd} key="jigyasu-form-link" rel="noreferrer">
                          <MiniStatisticsCard
                            title={{ text: "Add New Jigyasu", textColor: "white" }}
                            count=""
                            percentage={{ color: "success", text: "" }}
                            icon={{ color: "white", component: "public", iconOrder: "left" }}
                            backgroundColor="dark"
                            direction="left"
                          />
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <Link href={urld} key="jigyasu-form-link" rel="noreferrer">
                        <MiniStatisticsCard
                          title={{ text: "Draft", textColor: "white" }}
                          count={countdata?.draft ? countdata?.draft : 0}
                          percentage={{ color: "success", text: "" }}
                          icon={{ color: "info", component: "public", iconOrder: "left" }}
                          backgroundColor="secondary"
                          direction="left"
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <Link href={urlp} key="jigyasu-form-link" rel="noreferrer">
                        <MiniStatisticsCard
                          title={{ text: "In Process", textColor: "black" }}
                          count={countdata?.submitted ? countdata?.submitted : 0}
                          percentage={{ color: "success", text: "" }}
                          icon={{ color: "info", component: "public", iconOrder: "left" }}
                          backgroundColor="primary"
                          direction="left"
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <Link href={urla} key="jigyasu-form-link" rel="noreferrer">
                        <MiniStatisticsCard
                          title={{ text: "Approved" }}
                          count={countdata?.approved ? countdata?.approved : 0}
                          percentage={{ color: "success", text: "" }}
                          icon={{ color: "info", component: "public", iconOrder: "left" }}
                          backgroundColor="success"
                          direction="left"
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <Link href={urlr} key="jigyasu-form-link" rel="noreferrer">
                        <MiniStatisticsCard
                          title={{ text: "Returned for Correction" }}
                          count={countdata?.rejected ? countdata?.rejected : 0}
                          percentage={{ color: "success", text: "" }}
                          icon={{ color: "info", component: "public", iconOrder: "left" }}
                          backgroundColor="warning"
                          direction="left"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Card>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
