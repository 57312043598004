import * as Yup from "yup";
//import moment from "moment";
import checkoutFormModel from "./checkoutFormModel";
import axios from "axios";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

let userside = JSON.parse(localStorage.getItem("user"));
let AccessToken = userside ? userside["api"]["AccessToken"] : "Authorization";
const {
  formField: {
    branch,
    title,
    titlef,
    titlem,
    firstName,
    lastName,
    middleName,
    email,
    phone,
    qualification,
    occupation,
    nationality,
    dob,
    father_uid,
    mother_uid,
    spouse_uid,
    fatherName,
    motherName,
    spouse_name,
    address1,
    photo,
    city,
    zipcode,
    country,
    region,
    caste,
    bySecyVerification,
  },
} = checkoutFormModel;

//const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

export default [
  Yup.object().shape({
    [branch.name]: Yup.string().required(`${title.requiredErrorMsg}`),
    [firstName.name]: Yup.string(),
    [title.name]: Yup.string().required(`${title.requiredErrorMsg}`),
    [firstName.name]: Yup.string()
      .matches(/^[a-zA-Z ]*$/, "The first name must contain alpha characters only.")
      .required(`${firstName.requiredErrorMsg}`),
    [middleName.name]: Yup.string().matches(
      /^[a-zA-Z ]*$/,
      "The middle name must contain alpha characters only."
    ),
    [lastName.name]: Yup.string().matches(
      /^[a-zA-Z ]*$/,
      "The last name must contain alpha characters only."
    ),
    [dob.name]: Yup.string()
      .required(`${dob.requiredErrorMsg}`)
      .test("birthday", "User must be over 18 years old.", function (val) {
        return calculateAge(new Date(val)) >= 18;
      }),
  }),
  Yup.object().shape({
    [father_uid.name]: Yup.string()
      .required(`${father_uid.requiredErrorMsg}`)
      .test("father_uid", "Father UID '${value}' not Valid UID.", async (value) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_USER_DATA_COGNITO}/${value}`, {
            headers: {
              Authorization: AccessToken,
            },
          });
          const responseBody = response.data; // Axios automatically parses JSON response
          return responseBody && responseBody.length > 0;
        } catch (error) {
          return false;
        }
      })
      .test("father_uid", "Father UID '${value}' must be older then 3 year.", async (value) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_USER_DATA_COGNITO}/${value}`, {
            headers: {
              Authorization: AccessToken,
            },
          });
          const responseBody = response.data; // Axios automatically parses JSON response
          console.log(responseBody);
          return calculateInciationdiff(new Date(responseBody[0].first_doi)) >= 3;
        } catch (error) {
          return false;
        }
      }),
    [mother_uid.name]: Yup.string()
      .required(`${mother_uid.requiredErrorMsg}`)
      .test("mother_uid", "Mother UID '${value}' not Valid UID.", async (value) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_USER_DATA_COGNITO}/${value}`, {
            headers: {
              Authorization: AccessToken,
            },
          });
          const responseBody = response.data; // Axios automatically parses JSON response
          return responseBody && responseBody.length > 0;
        } catch (error) {
          return false;
        }
      })
      .test("mother_uid", "Mother UID '${value}' must be older then 3 year.", async (value) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_USER_DATA_COGNITO}/${value}`, {
            headers: {
              Authorization: AccessToken,
            },
          });
          const responseBody = response.data; // Axios automatically parses JSON response
          console.log(responseBody);
          return calculateInciationdiff(new Date(responseBody[0].first_doi)) >= 3;
        } catch (error) {
          return false;
        }
      }),

    [spouse_uid.name]: Yup.string().when("titles", {
      is: (titles) => ["PBN", "PB"].includes(titles),
      then: () => Yup.string().required(`${spouse_uid.requiredErrorMsg}`),
    }),
    [titlef.name]: Yup.string().required(`${titlef.requiredErrorMsg}`),
    [fatherName.name]: Yup.string()
      .matches(/^[a-zA-Z ]*$/, "The Father name must contain alpha characters only.")
      .required(`${fatherName.requiredErrorMsg}`),
    [titlem.name]: Yup.string().required(`${titlem.requiredErrorMsg}`),
    [motherName.name]: Yup.string()
      .matches(/^[a-zA-Z ]*$/, "The Mother name must contain alpha characters only.")
      .required(`${motherName.requiredErrorMsg}`),
    [spouse_name.name]: Yup.string()
      .matches(/^[a-zA-Z ]*$/, "The Father name must contain alpha characters only.")
      .when("titles", {
        is: (titles) =>
          ["PB", "PBN", "MR", "MS", "Late PB", "Late PBN", "Late MR", "Late MS"].includes(titles),
        then: () =>
          Yup.string()
            .matches(/^[a-zA-Z ]*$/, "The Father name must contain alpha characters only.")
            .required(`${spouse_name.requiredErrorMsg}`),
      }),
  }),
  Yup.object().shape({
    [father_uid.name]: Yup.string().when("titlef", {
      is: (titlef) => ["PB", "High"].includes(titlef),
      then: () =>
        Yup.string()
          .required(`${father_uid.requiredErrorMsg}`)
          .test("father_uid", "Father UID '${value}' not Valid UID.", async (value) => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_USER_DATA_COGNITO}/${value}`,
                {
                  headers: {
                    Authorization: AccessToken,
                  },
                }
              );
              const responseBody = response.data; // Axios automatically parses JSON response
              return responseBody && responseBody.length > 0;
            } catch (error) {
              return false;
            }
          }),
    }),
    [mother_uid.name]: Yup.string().when("titlem", {
      is: (titlem) => ["PBN", "High"].includes(titlem),
      then: () =>
        Yup.string()
          .required(`${mother_uid.requiredErrorMsg}`)
          .test("mother_uid", "Mother UID '${value}' not Valid UID.", async (value) => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_USER_DATA_COGNITO}/${value}`,
                {
                  headers: {
                    Authorization: AccessToken,
                  },
                }
              );
              const responseBody = response.data; // Axios automatically parses JSON response
              return responseBody && responseBody.length > 0;
            } catch (error) {
              return false;
            }
          }),
    }),
    [spouse_uid.name]: Yup.string().when("titles", {
      is: (titles) => ["PBN", "PB"].includes(titles),
      then: () => Yup.string().required(`${spouse_uid.requiredErrorMsg}`),
    }),
    [titlef.name]: Yup.string().required(`${titlef.requiredErrorMsg}`),
    [fatherName.name]: Yup.string()
      .matches(/^[a-zA-Z ]*$/, "The Father name must contain alpha characters only.")
      .required(`${fatherName.requiredErrorMsg}`),
    [titlem.name]: Yup.string().required(`${titlem.requiredErrorMsg}`),
    [motherName.name]: Yup.string()
      .matches(/^[a-zA-Z ]*$/, "The Mother name must contain alpha characters only.")
      .required(`${motherName.requiredErrorMsg}`),
    [spouse_name.name]: Yup.string()
      .matches(/^[a-zA-Z ]*$/, "The Father name must contain alpha characters only.")
      .when("titles", {
        is: (titles) =>
          ["PB", "PBN", "MR", "MS", "Late PB", "Late PBN", "Late MR", "Late MS"].includes(titles),
        then: () =>
          Yup.string()
            .matches(/^[a-zA-Z ]*$/, "The Father name must contain alpha characters only.")
            .required(`${spouse_name.requiredErrorMsg}`),
      }),
  }),
  Yup.object().shape({
    [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
    [city.name]: Yup.string().nullable().required(`${city.requiredErrorMsg}`),
    [region.name]: Yup.string().nullable().required(`${region.requiredErrorMsg}`),
    [caste.name]: Yup.string().nullable().required(`${caste.requiredErrorMsg}`),
    [zipcode.name]: Yup.string()
      .required(`${zipcode.requiredErrorMsg}`)
      .test("len", `${zipcode.invalidErrorMsg}`, (val) => val && val.length > 4),
    [country.name]: Yup.string().nullable().required(`${country.requiredErrorMsg}`),
    [email.name]: Yup.string().email().required(`${email.requiredErrorMsg}`),
    [phone.name]: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required(`${phone.requiredErrorMsg}`),
    [occupation.name]: Yup.string().nullable().required(`${occupation.requiredErrorMsg}`),
    [qualification.name]: Yup.string().nullable().required(`${qualification.requiredErrorMsg}`),
    [nationality.name]: Yup.string().nullable().required(`${nationality.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [bySecyVerification.name]: Yup.bool().oneOf(
      [true],
      "You need to accept the terms and conditions"
    ),
    [photo.name]: Yup.string().required(`${photo.requiredErrorMsg}`),
  }),
  Yup.object().shape({}),
];

function calculateAge(birthday) {
  var ageDifMs = birthday.getTime();
  var years = 0;
  var ageRestriction = new Date().setFullYear(new Date().getFullYear() - 18);
  if (ageDifMs < ageRestriction) {
    years = 18;
  } else {
    years = 0;
  }
  return Math.abs(years);
}

function calculateInciationdiff(birthday) {
  var ageDifMs = birthday.getTime();
  var years = 0;
  var ageRestriction = new Date().setFullYear(new Date().getFullYear() - 3);
  if (ageDifMs < ageRestriction) {
    years = 3;
  } else {
    years = 0;
  }
  return Math.abs(years);
}
