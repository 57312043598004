import React, { useState, useEffect } from "react";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";

import validationSchemaUV from "components/CheckoutPage/FormModel/validationSchemaUV";
import checkoutFormModel from "components/CheckoutPage/FormModel/checkoutFormModel";
import formInitialValuesUV from "components/CheckoutPage/FormModel/formInitialValuesUV";
import UveditForm from "components/CheckoutPage/Forms/uveditForm";

import useStyles from "components/CheckoutPage/styles";
// Dayalbagh New System React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import JigyasuApi from "api/jigyasu";
import { useParams } from "react-router-dom";
import { useAuth } from "auth-context/auth.context";
import axios from "axios";

const { formId, formField } = checkoutFormModel;

function _renderStepContent(step, datanew) {
  switch (step) {
    case 0:
      return <UveditForm formField={formField} formData={datanew} />;
    default:
      return <div>Your Data is saved in Draft</div>;
  }
}

export default function UvApprovedForm() {
  const classes = useStyles();
  const [activeStep] = useState(0);
  const [clickButton, setClickButton] = useState("main");
  const [loading, setLoading] = useState(false);
  const currentValidationSchema = validationSchemaUV[activeStep];
  const formInitialValuesUVdata = formInitialValuesUV;
  const params = useParams();
  let { user } = useAuth();
  let title = "";
  let titleb = "";
  if (user.role == "DBAPPROVER" || user.role == "UVVOLUNTER") {
    if (user.role == "DBAPPROVER") {
      title = "Approve";
      titleb = "Return to Branch Secretary";
    } else {
      title = "Validate";
      titleb = "Return to Branch Secretary";
    }
  }

  useEffect(async () => {
    let responceall = await JigyasuApi.GetJigyasuData(params.id);
    let responce = responceall["data"] ? responceall["data"]["data"]["0"] : [];
    console.log(responce, "-----ddddd----");
    formInitialValuesUVdata.uid = responce.uid ? responce.uid : "";
    formInitialValuesUVdata.title = responce.title ? responce.title : "";
    formInitialValuesUVdata.firstName = responce.first_name ? responce.first_name : "";
    formInitialValuesUVdata.lastName = responce.last_name ? responce.last_name : "";
    formInitialValuesUVdata.middleName = responce.middle_name ? responce.middle_name : "";
    formInitialValuesUVdata.email = responce.email ? responce.email : "";
    formInitialValuesUVdata.phone = responce.phone ? responce.phone : "";
    formInitialValuesUVdata.qualification = responce.qualification ? responce.qualification : "";
    formInitialValuesUVdata.organization = responce.organization ? responce.organization : "";
    formInitialValuesUVdata.occupation = responce.occupation ? responce.occupation : "";
    formInitialValuesUVdata.designation = responce.designation ? responce.designation : "";
    formInitialValuesUVdata.nationality = responce.nationality ? responce.nationality : "";
    formInitialValuesUVdata.branch = responce.branch ? responce.branch : "";
    formInitialValuesUVdata.dob = responce.dob ? responce.dob : "";
    formInitialValuesUVdata.father_uid = responce.father_uid ? responce.father_uid : "";
    formInitialValuesUVdata.mother_uid = responce.mother_uid ? responce.mother_uid : "";
    formInitialValuesUVdata.spouse_uid = responce.spouse_uid ? responce.spouse_uid : "";
    formInitialValuesUVdata.fatherName = responce.father_name ? responce.father_name : "";
    formInitialValuesUVdata.titlef = responce.titlef ? responce.titlef : "";
    formInitialValuesUVdata.motherName = responce.mother_name ? responce.mother_name : "";
    formInitialValuesUVdata.titlem = responce.titlem ? responce.titlem : "";
    formInitialValuesUVdata.spouse_name = responce.spouse_name ? responce.spouse_name : "";
    formInitialValuesUVdata.titles = responce.titles ? responce.titles : "";
    formInitialValuesUVdata.address1 = responce.address1 ? responce.address1 : "";
    formInitialValuesUVdata.bloodGroup = responce.bloodgroup ? responce.bloodgroup : "";
    formInitialValuesUVdata.address2 = responce.address2 ? responce.address2 : "";
    formInitialValuesUVdata.city = responce.city ? responce.city : "";
    formInitialValuesUVdata.zipcode = responce.zipcode ? responce.zipcode : "";
    formInitialValuesUVdata.country = responce.country ? responce.country : "";
    formInitialValuesUVdata.region = responce.region ? responce.region : "";
    formInitialValuesUVdata.status = responce.status ? responce.status : "";
    formInitialValuesUVdata.house = responce.house ? responce.house : "";
    formInitialValuesUVdata.photo = responce.photo ? responce.photo : "";
    formInitialValuesUVdata.caste = responce.caste ? responce.caste : "";
    formInitialValuesUVdata.workingHours = responce.workinghours ? responce.workinghours : "";
    formInitialValuesUVdata.note = "";
    if (user.role == "DBAPPROVER") {
      //formInitialValuesUVdata.note = "Application found in order and approved as per guidelines.";
    }
    formInitialValuesUVdata.vehicle = responce.vehicle ? responce.vehicle : "";
  }, []);

  async function _submitForm(values, actions) {
    try {
      actions.setSubmitting(false);
      var data = values;
      data.id = params.id;
      data.nee = "";

      //var date = new Date(data.dob);
      //var year = date.getFullYear().toString();
      //var month = (date.getMonth() + 1).toString().padStart(2, "0");
      //var day = date.getDate().toString().padStart(2, "0");
      console.log("1111");
      let timeout = "";
      console.log("2222");
      if (user.role == "DBAPPROVER") {
        setLoading(true);
        // Retrieve the token from local storage
        const tokenall = localStorage.getItem("user");
        const userdata = JSON.parse(tokenall);
        const token = userdata.api.AccessToken;
        const url = process.env.REACT_APP_DB_WRITER_APP_SERVER;
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token, // Replace 'accesstoken' with your actual access token
        };
        const currentDate = new Date();
        console.log("3333");
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");
        const hours = currentDate.getHours().toString().padStart(2, "0");
        const minutes = currentDate.getMinutes().toString().padStart(2, "0");
        const seconds = currentDate.getSeconds().toString().padStart(2, "0");
        const formatted = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        // Format the date if needed
        const formattedDate = currentDate.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        var dob = new Date(data?.dob).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        // Split the full name into parts
        const nameParts = data?.fatherName.split(" ");

        // Extract first, middle, and last names
        const father_firstName = nameParts[0] || ""; // First name
        const father_middleName = nameParts.slice(1, -1).join(" ") || ""; // Middle name (if any)
        const father_lastName = nameParts[nameParts.length - 1] || ""; // Last name
        // Split the full name into parts
        const mother_nameParts = data?.motherName.split(" ");

        // Extract first, middle, and last names
        const mother_firstName = mother_nameParts[0] || ""; // First name
        const mother_middleName = mother_nameParts.slice(1, -1).join(" ") || ""; // Middle name (if any)
        const mother_lastName = mother_nameParts[mother_nameParts.length - 1] || ""; // Last name

        // Split the full name into parts
        const spouse_nameParts = data?.spouse_name.split(" ");

        // Extract first, middle, and last names
        const spouse_firstName = spouse_nameParts[0] || ""; // First name
        const spouse_middleName = spouse_nameParts.slice(1, -1).join(" ") || ""; // Middle name (if any)
        const spouse_lastName = spouse_nameParts[spouse_nameParts.length - 1] || ""; // Last name
        console.log("5555");
        const datasend = {
          branch: data?.branch,
          jig_uid: data?.uid,
          title: data?.title ? data?.title : "MR",
          first_name: data.firstName.toUpperCase(),
          middle_name: data.middleName.toUpperCase(),
          last_name: data.lastName.toUpperCase(),
          father_uid: data?.father_uid,
          title_1: data?.titlef.replace("Late", ""),
          father_first_name: data?.titlef.includes("Late")
            ? "(LATE) " + father_firstName.toUpperCase()
            : father_firstName.toUpperCase(),
          father_middle_name: father_middleName.toUpperCase(),
          father_last_name: father_lastName.toUpperCase(),
          mother_uid: data?.mother_uid,
          title_2: data?.titlem.replace("Late", ""),
          mother_first_name: data?.titlem.includes("Late")
            ? "(LATE) " + mother_firstName.toUpperCase()
            : mother_firstName.toUpperCase(),
          mother_middle_name: mother_middleName.toUpperCase(),
          mother_last_name: mother_lastName.toUpperCase(),
          title_3: data.titles.replace("Late", ""),
          spouse_uid: data.spouse_uid,
          spouse_first_name: data?.titles.includes("Late")
            ? "(LATE) " + spouse_firstName.toUpperCase()
            : spouse_firstName.toUpperCase(),
          spouse_middle_name: spouse_middleName.toUpperCase(),
          spouse_last_name: spouse_lastName.toUpperCase(),
          dob: dob.replace(/\//g, "-"),
          caste: data.caste,
          occupation: data.occupation,
          address_line_1: data.address1.toUpperCase(),
          address_line_2: data.address2.toUpperCase(),
          address_line_3: "",
          city: data.city.toUpperCase(),
          pin: data.zipcode,
          state: data.region,
          country: data.country,
          dor: formattedDate.replace(/\//g, "-"),
          qualification: data.qualification,
          nationality: data.nationality,
          email_address: data?.email,
          phone_number: data.phone,
          creation_time: formatted,
          modification_time: formatted,
        };
        console.log("6666", datasend);
        try {
          const response = await axios.post(url, datasend, { headers });
          console.log("7777");
          console.log("API Response:", response.data);
        } catch (error) {
          console.log("API Error Approved:", error);
          setLoading(false);
          alert(error);
          return "setError(err.response.data.msg);";
        }
        console.log("8888");
        alert("Request has been Approved and New Jigyasu ID is :." + data?.uid);
        timeout = setTimeout(() => {
          // 👇️ redirects to an external URL
          //window.location.replace("/uv/jigyasu/validated");
        }, 1000);
      }
      actions.setSubmitting(false);

      return () => clearTimeout(timeout);
    } catch (err) {
      if (err.response) {
        return "setError(err.response.data.msg);";
      }
      return "setError('There has been an error.')";
    }
  }

  async function _rejectForm(values, actions) {
    try {
      actions.setSubmitting(false);
      var data = values;
      data.id = params.id;
      data.nee = "";
      data.status = "6";
      let response = await JigyasuApi.GetJigyasuDataRejected({ data });
      if (response.data && response.data.success === false) {
        return "setError(response.data.msg)";
      }
      alert("Request has been Return to Branch Secretary.");
      let timeout = "";
      if (user.role == "UVVOLUNTER") {
        timeout = setTimeout(() => {
          // 👇️ redirects to an external URL
          window.location.replace("/uv/jigyasu/list");
        }, 1000);
      } else if (user.role == "DBAPPROVER") {
        timeout = setTimeout(() => {
          // 👇️ redirects to an external URL
          window.location.replace("/uv/jigyasu/validated");
        }, 1000);
      }
      //actions.setSubmitting(false);
      actions.setSubmitting(false);
      return () => clearTimeout(timeout);
    } catch (err) {
      if (err.response) {
        return "setError(err.response.data.msg);";
      }
      return "setError('There has been an error.')";
    }
  }

  function _handleSubmit(values, actions) {
    if (clickButton == "draft") {
      _rejectForm(values, actions);
    } else {
      _submitForm(values, actions);
    }
  }

  function _setActiveButton() {
    setClickButton("draft");
  }

  function _setActiveMain() {
    setClickButton("main");
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <React.Fragment>
        <Typography component="h1" variant="h4" align="center">
          Jigyasu Form
        </Typography>
        <React.Fragment>
          <Formik
            initialValues={formInitialValuesUV}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(0, formInitialValuesUVdata)}

                <div className={classes.buttons}>
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => _setActiveMain()}
                      className={classes.button}
                    >
                      {title}
                    </Button>
                    <Button
                      onClick={() => _setActiveButton()}
                      className={classes.button}
                      type="submit"
                    >
                      {titleb}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: "42px", color: "#fff" }}>Data is Saving. please wait...</div>
            </div>
          )}
        </React.Fragment>
      </React.Fragment>
      <Footer />
    </DashboardLayout>
  );
}

/*function calculateAge(birthday) {
  var ageDifMs = Date.now() - birthday;
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}*/
