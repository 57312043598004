/**
=========================================================
* Dayalbagh New System React - v2.0.0
=========================================================

* Product Page: https://www.hurekatek.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Hureka Technologies Inc. (https://www.hurekatek.com)

Coded by www.hurekatek.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
//import Card from "@mui/material/Card";

// Dayalbagh New System React components
//import SuiBox from "components/SuiBox";
//import SuiTypography from "components/SuiTypography";

// Dayalbagh New System React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import Table from "examples/Table";

// Custom styles for the Tables
//import styles from "layouts/tables/styles";
import React, { useState, useEffect } from "react";
import JigyasuApi from "api/jigyasu";
//import ProfilesList from "examples/ProfilesList";
import { useAuth } from "auth-context/auth.context";
//import { useHistory } from "react-router-dom";
import SuiTypography from "components/SuiTypography";
import Popup from "components/Popup";
import JigyasuData from "layouts/JigyasuData";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Link,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";

//import { Table } from "@mui/material";
// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";

function Jiguasuuvlistav() {
  //const classes = styles();
  const [rows, setData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noRecords, setNoRecords] = useState(false);
  const [data, setDataDup] = useState(null);
  let { user } = useAuth();
  if (user.role == "BBRANCHSEC") {
    //const history = useHistory();
    //history.push("/jigyasu/list");
  }
  /*   let title = "Jigyasu List";
  if (user.role == "UVVOLUNTER") {
    title = "Validated Jigyasu List";
  } else if (user.role == "DBAPPROVER") {
    title = "Approved Jigyasu List";
  } */

  useEffect(async () => {
    //let usersend = localStorage.getItem("user");
    //usersend = JSON.parse(usersend);
    var data = {};
    data.call = 2;
    let responce = await JigyasuApi.JigyasuDataAPI({ data });
    let responce_data = responce?.data?.data ? responce?.data?.data : [];
    let role = responce?.data?.role ? responce?.data?.role : [];
    let responce_all = [];
    responce_data
      .sort((a, b) => (a.id > b.id ? -1 : 1))
      .map((row, key) => {
        const formattedDate = format(new Date(row.dob), "dd-MM-yyyy");
        const nameParts = row?.father_name.split(" ");
        const father_firstName = nameParts[0] || ""; // First name
        const father_middleName = nameParts.slice(1, -1).join(" ") || ""; // Middle name (if any)
        const father_lastName = nameParts[nameParts.length - 1] || ""; // Last name
        var responce_all_data = {
          id: row.id,
          link: row.id,
          key: key,
          father_first_name: father_firstName,
          father_middle_name: father_middleName ? father_middleName : "",
          father_last_name: father_lastName ? father_lastName : "",
          first_name: row.first_name,
          middle_name: row.middle_name ? row.middle_name : "",
          last_name: row.last_name ? row.last_name : "",
          email: row.email,
          father_name: row.father_name,
          mother_name: row.mother_name,
          city: row.city,
          region: row.region,
          country: row.country,
          zipcode: row.zipcode,
          branch: row.branch,
          status: row.StatusName?.StatusName,
          action: row.id,
          role: role,
          dob: formattedDate,
        };
        responce_all.push(responce_all_data);
      });
    //console.log("responce_data---" + JSON.stringify(responce_data));
    setData(responce_all);
  }, []);
  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const columnsx = [
    {
      field: "link",
      headerName: "ID",
      width: 100,
      renderCell: (params) => {
        const rowId = params.row.link;
        return (
          <SuiTypography
            component="a"
            href="#"
            variant="button"
            color="primary"
            fontWeight="medium"
            onClick={() => {
              openInPopup(rowId);
            }}
          >
            {rowId}
          </SuiTypography>
        );
      },
    },
    {
      field: "first_name",
      headerName: "First name",
      width: 100,
      editable: true,
    },
    {
      field: "last_name",
      headerName: "Last name",
      width: 100,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      width: 150,
      editable: true,
    },
    {
      field: "father_name",
      headerName: "Father Name",
      width: 150,
      editable: true,
    },
    {
      field: "mother_name",
      headerName: "Mother Name",
      width: 150,
      editable: true,
    },
    {
      field: "city",
      headerName: "City",
      width: 110,
      editable: true,
    },
    {
      field: "region",
      headerName: "State",
      width: 50,
      editable: true,
    },
    {
      field: "country",
      headerName: "Country",
      width: 100,
      editable: true,
    },
    {
      field: "zipcode",
      headerName: "Zipcode",
      width: 100,
      editable: true,
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 110,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        const rowId = params.row.link;
        const role = params.row.role;
        if (role == "DBAPPROVER") {
          return (
            <>
              <SuiTypography
                component="a"
                href={`/uv/check/validate/` + rowId}
                variant="button"
                color="primary"
                fontWeight="medium"
              >
                Check And Approve
              </SuiTypography>
              <Link
                component="button"
                variant="body2"
                onClick={() => handleCheckDuplicate(params.row)}
              >
                Check Duplicate
              </Link>
            </>
          );
        } else {
          return "";
        }
      },
    },
  ];
  const handleCheckDuplicate = (row) => {
    console.log("row----------", row);
    const {
      first_name,
      middle_name,
      last_name,
      father_first_name,
      father_middle_name,
      father_last_name,
      dob,
    } = row;
    const apiUrl = `https://pw1cyipfnc.execute-api.ap-south-1.amazonaws.com/jigyasu_dedup_data?first_name=${first_name}&middle_name=${middle_name}&last_name=${last_name}&father_first_name=${father_first_name}&father_middle_name=${father_middle_name}&dob=${dob}&father_last_name=${father_last_name}`;

    setLoading(true);
    setOpen(true);
    let userside = JSON.parse(localStorage.getItem("user"));
    let AccessToken = userside ? userside["api"]["AccessToken"] : "Authorization";
    axios
      .get(apiUrl, {
        headers: {
          Authorization: AccessToken,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          setDataDup(response.data);
          setNoRecords(false);
        } else {
          setNoRecords(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        setNoRecords(true);
      });
  };
  const handleClose = () => {
    setOpen(false);
    setDataDup(null);
    setNoRecords(false);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DataGrid
        rows={rows}
        columns={columnsx}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns email and zipcode, the other columns will remain visible
              email: false,
              zipcode: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        slots={{ toolbar: GridToolbar }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
      <Popup title="Jigyasu Data" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <JigyasuData recordForEdit={recordForEdit} />
      </Popup>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Check for Duplicates</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : noRecords ? (
            <p>No Duplicate records found</p>
          ) : (
            <div>
              {data &&
                data.map((record, index) => (
                  <div key={index}>
                    <p>
                      <strong>Branch:</strong> {record.branch}
                    </p>
                    <p>
                      <strong>First Name:</strong> {record.first_name}
                    </p>
                    <p>
                      <strong>Last Name:</strong> {record.last_name}
                    </p>
                    {/* Add more fields as needed */}
                    <hr />
                  </div>
                ))}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default Jiguasuuvlistav;
