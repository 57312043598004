import React from "react";

const qualifications = [
  {
    value: 50,
    label: "Illiterate",
  },
  {
    value: 51,
    label: "Literate but Class 2 or lower",
  },
  {
    value: 52,
    label: "Class 2 or higher but Class 5 or lower",
  },
  {
    value: 53,
    label: "Class 6 or higher but Class 9 or lower",
  },
  {
    value: 54,
    label: "Class 10 or higher but Class 11 or lower",
  },
  {
    value: 55,
    label: "Class 12",
  },
  {
    value: 56,
    label: "Completed Vocational Certificate",
  },
  {
    value: 57,
    label: "Completed Diploma",
  },
  {
    value: 58,
    label: "Completed Non-professional Bachelor's degree (eg. BA, BSc, etc.)",
  },
  {
    value: 59,
    label: "Completed Professional Bachelor's degree (eg. BTech, MBBS, etc.)",
  },
  {
    value: 60,
    label: "Completed Professional Certification (CA, CS, etc.)",
  },
  {
    value: 61,
    label: "Completed Non-professional Master's degree (eg. MA, MSc, etc.)",
  },
  {
    value: 62,
    label: "Completed Professional Master's degree (eg. MTech, etc.)",
  },
  {
    value: 63,
    label: "Completed Doctorate",
  },
  {
    value: 70,
    label: "Other Qualification",
  },
];

const LabelForQualification = ({ value }) => {
  const qualification = qualifications.find((qual) => qual.value == value);
  const label = qualification ? qualification.label : "Unknown Qualification";

  return <span>{label}</span>;
};

export default LabelForQualification;
