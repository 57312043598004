/**
=========================================================
* Dayalbagh New System React - v2.0.0
=========================================================

* Product Page: https://www.hurekatek.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Hureka Technologies Inc. (https://www.hurekatek.com)

Coded by www.hurekatek.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Dayalbagh New System React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Dayalbagh New System React layouts
import Dashboard from "layouts/dashboard";
import Dashboarduv from "layouts/dashboarduv";
//import DashboardVR from "layouts/dashboardvr";
import Tables from "layouts/tables";
import Inprocess from "layouts/inprocess";
//import Vrjigyasu from "layouts/vrjigyasu";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import SignOut from "layouts/authentication/sign-out";

// forms
import JigyasuForm from "layouts/forms/jigyasu";
//import Jigyasu from "layouts/jigyasu";
// Dayalbagh New System React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";

import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
//import Jigyasu from "layouts/jigyasu";
import Jiguasuuvlist from "layouts/jiguasuuvlist";
import JiguasuuvSubmitlist from "layouts/JiguasuuvSubmitlist";
import Jiguasuuvlistav from "layouts/jiguasuuvlistav";
import JiguasuuvlistApproved from "layouts/jiguasuuvlistapproved";
import JiguasuuvlistRejected from "layouts/jiguasuuvlistrejected";
import UvValidateForm from "layouts/uvvalidate";
import UvApprovedForm from "layouts/uvvalidateadmin";
import JiguasuuvlistDraft from "layouts/jiguasuuvlistdraft";
import JiguasuuvByIDAPI from "layouts/JiguasuuvByIDAPI";
import BcResponseForm from "layouts/bcresponce";
import BcDraftForm from "layouts/bcdraft";

const routes = [
  {
    type: "collapse",
    name: "Jigyasu Registration",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: Dashboard,
    noCollapse: true,
    protected: true,
    roles: "BBRANCHSEC",
  },
  {
    type: "collapse",
    name: "Jigyasu Registration",
    key: "dashboard",
    route: "/vr/dashboard",
    icon: <Shop size="12px" />,
    component: Dashboarduv,
    noCollapse: true,
    protected: true,
    roles: "DATAVIEWER",
  },
  {
    type: "collapse",
    name: "Jigyasu Registration",
    key: "Dashboarduv",
    route: "/uv/dashboard",
    icon: <Shop size="12px" />,
    component: Dashboarduv,
    noCollapse: true,
    protected: true,
    roles: "UVVOLUNTER",
  },
  {
    type: "none",
    name: "Jigyasu Form",
    key: "Jigyasu Form",
    icon: <Shop size="12px" />,
    route: "/jigyasu/form",
    component: JigyasuForm,
    roles: "BBRANCHSEC",
  },
  {
    type: "none",
    name: "Users",
    key: "tables",
    route: "/users",
    icon: <Office size="12px" />,
    component: Tables,
    noCollapse: true,
    protected: true,
    roles: "BBRANCHSEC",
  },
  {
    type: "none",
    name: "Jigyasu Inprocess List",
    key: "tables",
    route: "/uv/jigyasu/inprocess",
    icon: <Office size="12px" />,
    component: Inprocess,
    noCollapse: true,
    protected: true,
    roles: "BBRANCHSEC",
  },
  {
    type: "none",
    name: "Jigyasu List",
    key: "tables",
    route: "/jigyasu/list",
    icon: <Office size="12px" />,
    component: Tables,
    noCollapse: true,
    protected: true,
    roles: "BBRANCHSEC",
  },
  {
    type: "none",
    name: "Jigyasu List dynamic",
    key: "dynamicd",
    route: `/jigyasu/data/:id`,
    icon: <Office size="12px" />,
    component: UvValidateForm,
    noCollapse: true,
    protected: true,
    roles: "BBRANCHSEC",
  },
  {
    type: "none",
    name: "Jigyasu Response",
    key: "response",
    route: `/jigyasu/:id/response`,
    icon: <Office size="12px" />,
    component: BcResponseForm,
    noCollapse: true,
    protected: true,
    roles: "BBRANCHSEC",
  },
  {
    type: "none",
    name: "Jigyasu Draft",
    key: "draftedit",
    route: `/jigyasu/:id/edit`,
    icon: <Office size="12px" />,
    component: BcDraftForm,
    noCollapse: true,
    protected: true,
    roles: "BBRANCHSEC",
  },
  {
    type: "none",
    name: "Jigyasu List dynamic",
    key: "dynamic",
    route: `/uv/check/validate/:id`,
    icon: <Office size="12px" />,
    component: UvValidateForm,
    noCollapse: true,
    protected: true,
    roles: "all",
  },
  {
    type: "none",
    name: "Jigyasu Approved",
    key: "onlyadminapproved",
    route: `/uv/approved/validate/:id`,
    icon: <Office size="12px" />,
    component: UvApprovedForm,
    noCollapse: true,
    protected: true,
    roles: "all",
  },
  {
    type: "none",
    name: "Jigyasu List",
    key: "tables",
    route: "/uv/jigyasu/list",
    icon: <Office size="12px" />,
    component: Jiguasuuvlist,
    noCollapse: true,
    protected: true,
    roles: "UVVOLUNTER",
  },
  {
    type: "none",
    name: "Jigyasu Submitted data List",
    key: "JiguasuuvSubmitlist",
    route: "/uv/jigyasu/submited/list",
    icon: <Office size="12px" />,
    component: JiguasuuvSubmitlist,
    noCollapse: true,
    protected: true,
    roles: "UVVOLUNTER",
  },
  {
    type: "none",
    name: "Jigyasu API data",
    key: "apidata",
    route: `/uv/jigyasu/submited/details/:id`,
    icon: <Office size="12px" />,
    component: JiguasuuvByIDAPI,
    noCollapse: true,
    protected: true,
    roles: "UVVOLUNTER",
  },
  {
    type: "none",
    name: "Jigyasu List Draft",
    key: "draft",
    route: "/uv/jigyasu/draft",
    icon: <Office size="12px" />,
    component: JiguasuuvlistDraft,
    noCollapse: true,
    protected: true,
    roles: "UVVOLUNTER",
  },
  {
    type: "none",
    name: "Jigyasu List Approved",
    key: "approved",
    route: "/uv/jigyasu/approved",
    icon: <Office size="12px" />,
    component: JiguasuuvlistApproved,
    noCollapse: true,
    protected: true,
    roles: "UVVOLUNTER",
  },
  {
    type: "none",
    name: "Jigyasu List validated",
    key: "validated",
    route: "/uv/jigyasu/validated",
    icon: <Office size="12px" />,
    component: Jiguasuuvlistav,
    noCollapse: true,
    protected: true,
    roles: "UVVOLUNTER",
  },
  {
    type: "none",
    name: "Jigyasu List Rejected",
    key: "rejected",
    route: "/uv/jigyasu/rejected",
    icon: <Office size="12px" />,
    component: JiguasuuvlistRejected,
    noCollapse: true,
    protected: true,
    roles: "UVVOLUNTER",
  },
  { type: "title", title: "Account Pages", key: "account-pages", roles: "alls" },
  {
    type: "none",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: Profile,
    noCollapse: true,
    protected: true,
    roles: "all",
  },
  {
    type: "none",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: SignIn,
    noCollapse: true,
    roles: "all",
  },
  {
    type: "none",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <SpaceShip size="12px" />,
    component: SignUp,
    noCollapse: true,
    roles: "all",
  },
  {
    type: "collapse",
    name: "Logout",
    key: "sign-out",
    route: "/authentication/sign-out",
    icon: <SpaceShip size="12px" />,
    component: SignOut,
    noCollapse: true,
    roles: "all",
  },
];

export default routes;
