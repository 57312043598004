import axios from "./authindex";

class JigyasuApi {
  static Create = (data) => {
    data.user = user;
    return axios.post(`${base}`, data);
  };

  static Get = async (data) => {
    let baseget = "jigyasu";
    return await axios.get(`${baseget}`, data).catch((err) => console.log(err));
    // return axios.post(`${base}/logout`, data, { headers: { Authorization: `${data.token}` } });
  };
  static GetJigyasuUV = async (data) => {
    let baseget = "uv/jigyasu";
    return axios.get(`${baseget}`, data).catch((err) => console.log(err));
  };
  static GetJigyasuData = async (data) => {
    let basegetdata = "uv/jigyasu?id=" + data;
    let dataall = axios.get(`${basegetdata}`).catch((err) => console.log(err));
    return dataall;
  };
  static GetJigyasuDataUpdate = async (data) => {
    let basegetdata = "uv/jigyasu/update";
    let dataall = axios.post(`${basegetdata}`, data).catch((err) => console.log(err));
    return dataall;
  };
  static GetJigyasuDataRejected = async (data) => {
    let basegetdata = "uv/jigyasu/rejected";
    let dataall = axios.post(`${basegetdata}`, data).catch((err) => console.log(err));
    return dataall;
  };
  static GetJigyasuDataReturn = async (data) => {
    let basegetdata = "uv/jigyasu/return";
    let dataall = axios.post(`${basegetdata}`, data).catch((err) => console.log(err));
    return dataall;
  };
  static Update = async (data) => {
    data.user = user;
    return axios.post(`${base}/update`, data).catch((err) => console.log(err));
  };
  static UploadImage = async (data) => {
    data.user = user;
    return axios.post(`images`, data).catch((err) => console.log(err));
  };
  static GetJigyasuUVDataCount = async (data) => {
    let basegetdata = "uv/jigyasu/count";
    let dataall = axios.get(`${basegetdata}`, data).catch((err) => console.log(err));
    return dataall;
  };
  static GetJigyasuUVAV = async (data) => {
    let basegetdata = "uv/jigyasu/av";
    let dataall = axios.get(`${basegetdata}`, data).catch((err) => console.log(err));
    return dataall;
  };
  static GetJigyasuDataCount = async (data) => {
    let basegetdata = "bs/jigyasu/count";
    let dataall = axios.get(`${basegetdata}`, data).catch((err) => console.log(err));
    return dataall;
  };
  static GetJigyasuLastID = async (data) => {
    let basegetdata = "uv/jigyasu/lastid";
    let dataall = axios.post(`${basegetdata}`, data).catch((err) => console.log(err));
    return dataall;
  };
  static JigyasuDataAPI = async (data) => {
    let basegetdata = "uv/jigyasu/data";
    return await axios.post(`${basegetdata}`, data).catch((err) => console.log(err));
  };
  static JigyasuInporcessAPI = async (data) => {
    let basegetdata = "uv/jigyasu/inporcess";
    return await axios.post(`${basegetdata}`, data).catch((err) => console.log(err));
  };
  static BranchCountAPI = async (data) => {
    let basegetdata = "uv/jigyasu/location";
    return await axios.post(`${basegetdata}`, data).catch((err) => console.log(err));
  };
}

let base = "jigyasu";
let user = localStorage.getItem("user");
user = JSON.parse(user);

export default JigyasuApi;
