/**
=========================================================
* Dayalbagh New System React - v2.0.0
=========================================================

* Product Page: https://www.hurekatek.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Hureka Technologies Inc. (https://www.hurekatek.com)

Coded by www.hurekatek.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Dayalbagh New System React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Dayalbagh New System React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Table";

// Custom styles for the Tables
import styles from "layouts/tables/styles";
import React, { useState, useEffect } from "react";
import JigyasuApi from "api/jigyasu";
import { useAuth } from "auth-context/auth.context";
import { useHistory } from "react-router-dom";
// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";

function Tables() {
  const classes = styles();
  const [rows, setData] = useState([]);
  let { user } = useAuth();
  if (user.role != "BBRANCHSEC") {
    const history = useHistory();
    history.push("/uv/jigyasu/list");
  }
  const columns = [
    { name: "id", align: "left" },
    { name: "first_name", align: "left" },
    { name: "last_name", align: "left" },
    { name: "email", align: "left" },
    { name: "father_name", align: "left" },
    { name: "father_uid", align: "left" },
    { name: "mother_name", align: "left" },
    { name: "mother_uid", align: "left" },
    { name: "address1", align: "left" },
    { name: "address2", align: "left" },
    { name: "city", align: "left" },
    { name: "region", align: "left" },
    { name: "country", align: "left" },
    { name: "zipcode", align: "left" },
    { name: "branch", align: "left" },
    { name: "Status", align: "center" },
    { name: "action", align: "center" },
  ];
  useEffect(async () => {
    let usersend = localStorage.getItem("user");
    usersend = JSON.parse(usersend);
    let responce = await JigyasuApi.Get({ usersend });
    let responce_data = responce?.data ? responce?.data : [];
    let responce_all = [];
    responce_data
      .sort((a, b) => (a.id > b.id ? -1 : 1))
      .map((row, key) => {
        var responce_all_data = {
          id: row.id,
          key: key,
          first_name: row.first_name,
          last_name: row.last_name,
          email: row.email,
          father_name: row.father_name,
          father_uid: row.father_uid,
          mother_name: row.mother_name,
          mother_uid: row.mother_uid,
          address1: row.address1,
          address2: row.address2,
          city: row.city,
          region: row.region,
          country: row.country,
          zipcode: row.zipcode,
          branch: row.branch,
          Status: row.StatusName?.StatusName,
          action: row.StatusName?.StatusID == 7 || row.StatusName?.StatusID == 6 ? "Edit" : "",
        };
        responce_all.push(responce_all_data);
      });
    //console.log("responce_data---" + JSON.stringify(responce_data));
    setData(responce_all);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Jigyasu List</SuiTypography>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              <Table columns={columns} rows={rows} />
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
