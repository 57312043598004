import React from "react";

const occupations = [
  {
    value: 10,
    label: "Full-time Student",
  },
  {
    value: 11,
    label: "Not Employed (such as housewife)",
  },
  {
    value: 12,
    label: "Unemployed (actively seeking employment)",
  },
  {
    value: 13,
    label: "Retired",
  },
  {
    value: 14,
    label: "Agriculture, Farming and Forestry",
  },
  {
    value: 15,
    label: "Architecture and Engineering",
  },
  {
    value: 16,
    label: "Arts, Design, Entertainment, Sports, and Media",
  },
  {
    value: 17,
    label: "Building and Grounds Cleaning and Maintenance",
  },
  {
    value: 18,
    label: "Business and Financial Operations",
  },
  {
    value: 19,
    label: "Civil Services (Administrative Services)",
  },
  {
    value: 20,
    label: "Community and Social Service",
  },
  {
    value: 21,
    label: "Computer, Software and Mathematical, etc.",
  },
  {
    value: 22,
    label: "Construction and Extraction",
  },
  {
    value: 23,
    label: "Education, Training, and Library",
  },
  {
    value: 24,
    label: "Food Preparation and Serving Related",
  },
  {
    value: 25,
    label: "Healthcare Practitioners and Technical",
  },
  {
    value: 26,
    label: "Healthcare Support",
  },
  {
    value: 27,
    label: "Installation, Maintenance, and Repair",
  },
  {
    value: 28,
    label: "Legal",
  },
  {
    value: 29,
    label: "Life, Physical, and Social Science",
  },
  {
    value: 30,
    label: "Management",
  },
  {
    value: 31,
    label: "Military Specific",
  },
  {
    value: 32,
    label: "Office and Administrative Support",
  },
  {
    value: 33,
    label: "Personal Care and Service",
  },
  {
    value: 34,
    label: "Production",
  },
  {
    value: 35,
    label: "Protective Service (Police and related)",
  },
  {
    value: 36,
    label: "Sales and Related",
  },
  {
    value: 37,
    label: "Transportation and Material Moving",
  },
  {
    value: 40,
    label: "Other Occupations",
  },
];

const LabelForOccupation = ({ value }) => {
  const occupation = occupations.find((oc) => oc.value == value);
  const label = occupation ? occupation.label : "Unknown Occupation";

  return <span>{label}</span>;
};

export default LabelForOccupation;
