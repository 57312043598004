/**
=========================================================
* Dayalbagh New System React - v2.0.0
=========================================================

* Product Page: https://www.hurekatek.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Hureka Technologies Inc. (https://www.hurekatek.com)

Coded by www.hurekatek.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
//import Card from "@mui/material/Card";

// Dayalbagh New System React components
//import SuiBox from "components/SuiBox";
//import SuiTypography from "components/SuiTypography";

// Dayalbagh New System React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import Table from "examples/Table";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// Custom styles for the Tables
//import styles from "layouts/tables/styles";
import React, { useState, useEffect } from "react";
//import ProfilesList from "examples/ProfilesList";
import { useAuth } from "auth-context/auth.context";
import { useParams } from "react-router-dom";
//import { useHistory } from "react-router-dom";
//import { Table } from "@mui/material";
// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";

function JiguasuuvByIDAPIX() {
  //const classes = styles();
  const [rows, setData] = useState([]);
  const params = useParams();
  let { user } = useAuth();
  if (user.role == "BBRANCHSEC") {
    //const history = useHistory();
    //history.push("/jigyasu/list");
  }
  useEffect(() => {
    const fetchData = async () => {
      const url = process.env.REACT_APP_USER_DATA_COGNITO_BY_UID + params.id;

      // Retrieve the token from local storage
      const tokenall = localStorage.getItem("user");
      const userdata = JSON.parse(tokenall);
      const token = userdata.api.AccessToken;
      // Replace 'your_token_key' with the actual key you used to store the token

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          var data = {};
          data.call = 1;
          let responce_data = await response.json();
          console.log("responce", responce_data);
          let responce_all = [];
          responce_data
            .sort((a, b) => (a.id > b.id ? -1 : 1))
            .map((row) => {
              var responce_all_data = {
                id: row.jig_uid,
                link: row.jig_uid,
                key: row.jig_uid,
                Address_Line1: row.Address_Line1,
                Address_Line2: row.Address_Line2,
                Address_Line3: row.Address_Line3,
                Affiliation: row.Affiliation,
                Caste: row.Caste,
                City: row.City,
                Country: row.Country,
                Creation_Time: row.Creation_Time,
                DOB_DD: row.DOB_DD,
                DOB_MM: row.DOB_MM,
                DOB_YYYY: row.DOB_YYYY,
                DOR_DD: row.DOR_DD,
                DOR_MM: row.DOR_MM,
                DOR_YYYY: row.DOR_YYYY,
                Email_Address: row.Email_Address,
                Father_First_Name: row.Father_First_Name,
                Father_Last_Name: row.Father_Last_Name,
                Father_Middle_Name: row.Father_Middle_Name,
                Father_UID: row.Father_UID,
                First_Name: row.First_Name,
                Last_Name: row.Last_Name,
                Middle_Name: row.Middle_Name,
                Modification_Time: row.Modification_Time,
                Modified_By: row.Modified_By,
                Mother_First_Name: row.Mother_First_Name,
                Mother_Last_Name: row.Mother_Last_Name,
                Mother_Middle_Name: row.Mother_Middle_Name,
                Mother_Uid: row.Mother_Uid,
                Nationality: row.Nationality,
                Occupation: row.Occupation,
                Phone_Number: row.Phone_Number,
                Pin: row.Pin,
                Qualification: row.Qualification,
                Spouse_First_Name: row.Spouse_First_Name,
                Spouse_Last_Name: row.Spouse_Last_Name,
                Spouse_Middle_Name: row.Spouse_Middle_Name,
                Spouse_Uid: row.Spouse_Uid,
                State: row.State,
                Title: row.Title,
                Title1: row.Title1,
                Title2: row.Title2,
                Title3: row.Title3,
                jig_uid: row.jig_uid,
                status_flag: row.status_flag,
                role: "",
              };
              responce_all.push(responce_all_data);
            });
          //console.log("responce_data---" + JSON.stringify(responce_data));
          setData(responce_all);
        } else {
          console.error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the function to fetch data when the component mounts
    fetchData();
  }, []); // The empty dependency array ensures that the effect runs once after the initial render

  const columnsx = [
    {
      field: "link",
      headerName: "UID",
      width: 250,
      renderCell: (params) => {
        const rowId = params.row.link;
        return rowId;
      },
    },
    {
      field: "First_Name",
      headerName: "First Name",
      width: 100,
      editable: true,
    },
    {
      field: "Middle_Name",
      headerName: "Middle Name",
      width: 100,
      editable: true,
    },
    {
      field: "Last_Name",
      headerName: "Last Name",
      width: 100,
      editable: true,
    },
    {
      field: "Email_Address",
      headerName: "Email",
      type: "email",
      width: 150,
      editable: true,
    },
    {
      field: "Address_Line1",
      headerName: "Address Line1",
      width: 150,
      editable: true,
    },
    {
      field: "Address_Line2",
      headerName: "Address Line2",
      width: 150,
      editable: true,
    },
    {
      field: "Address_Line3",
      headerName: "Address Line3",
      width: 110,
      editable: true,
    },
    {
      field: "Affiliation",
      headerName: "Affiliation",
      width: 50,
      editable: true,
    },
    {
      field: "Caste",
      headerName: "Caste",
      width: 100,
      editable: true,
    },
    {
      field: "City",
      headerName: "City",
      width: 100,
      editable: true,
    },
    {
      field: "Country",
      headerName: "Country",
      width: 110,
      editable: true,
    },
    {
      field: "Creation_Time",
      headerName: "Creation Time",
      width: 150,
      editable: true,
    },
    {
      field: "DOB_DD",
      headerName: "DOB_DD",
      width: 150,
      editable: true,
    },
    {
      field: "DOB_MM",
      headerName: "DOB_MM",
      width: 150,
      editable: true,
    },
    {
      field: "DOB_YYYY",
      headerName: "DOB_YYYY",
      width: 150,
      editable: true,
    },
    {
      field: "DOR_DD",
      headerName: "DOR_DD",
      width: 150,
      editable: true,
    },
    {
      field: "DOR_MM",
      headerName: "DOR_MM",
      width: 150,
      editable: true,
    },
    {
      field: "DOR_YYYY",
      headerName: "DOR_YYYY",
      width: 150,
      editable: true,
    },
    {
      field: "Father_First_Name",
      headerName: "Father_First_Name",
      width: 150,
      editable: true,
    },
    {
      field: "Father_Last_Name",
      headerName: "Father_Last_Name",
      width: 150,
      editable: true,
    },
    {
      field: "Father_Middle_Name",
      headerName: "Father_Middle_Name",
      width: 150,
      editable: true,
    },
    {
      field: "Father_UID",
      headerName: "Father_UID",
      width: 150,
      editable: true,
    },
    {
      field: "Modification_Time",
      headerName: "Modification_Time",
      width: 150,
      editable: true,
    },
    {
      field: "Modified_By",
      headerName: "Modified_By",
      width: 150,
      editable: true,
    },
    {
      field: "Mother_First_Name",
      headerName: "Mother_First_Name",
      width: 150,
      editable: true,
    },
    {
      field: "Mother_Last_Name",
      headerName: "Mother_Last_Name",
      width: 150,
      editable: true,
    },
    {
      field: "Mother_Middle_Name",
      headerName: "Mother_Middle_Name",
      width: 150,
      editable: true,
    },
    {
      field: "Mother_Uid",
      headerName: "Mother_Uid",
      width: 150,
      editable: true,
    },
    {
      field: "Nationality",
      headerName: "Nationality",
      width: 150,
      editable: true,
    },
    {
      field: "Occupation",
      headerName: "Occupation",
      width: 150,
      editable: true,
    },
    {
      field: "Phone_Number",
      headerName: "Phone_Number",
      width: 150,
      editable: true,
    },
    {
      field: "Pin",
      headerName: "Pin",
      width: 150,
      editable: true,
    },
    {
      field: "Qualification",
      headerName: "Qualification",
      width: 150,
      editable: true,
    },
    {
      field: "Spouse_First_Name",
      headerName: "Spouse_First_Name",
      width: 150,
      editable: true,
    },
    {
      field: "Spouse_Last_Name",
      headerName: "Spouse_Last_Name",
      width: 150,
      editable: true,
    },
    {
      field: "Spouse_Middle_Name",
      headerName: "Spouse_Middle_Name",
      width: 150,
      editable: true,
    },
    {
      field: "Spouse_Uid",
      headerName: "Spouse_Uid",
      width: 150,
      editable: true,
    },
    {
      field: "State",
      headerName: "State",
      width: 150,
      editable: true,
    },
    {
      field: "Title",
      headerName: "Title",
      width: 150,
      editable: true,
    },
    {
      field: "Title1",
      headerName: "Title1",
      width: 150,
      editable: true,
    },
    {
      field: "Title2",
      headerName: "Title2",
      width: 150,
      editable: true,
    },
    {
      field: "Title3",
      headerName: "Title3",
      width: 150,
      editable: true,
    },
    {
      field: "jig_uid",
      headerName: "jig_uid",
      width: 150,
      editable: true,
    },
    {
      field: "status_flag",
      headerName: "status_flag",
      width: 150,
      editable: true,
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DataGrid
        rows={rows}
        columns={columnsx}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns email and zipcode, the other columns will remain visible
              email: false,
              zipcode: false,
            },
          },
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        slots={{ toolbar: GridToolbar }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
      <Footer />
    </DashboardLayout>
  );
}

export default JiguasuuvByIDAPIX;
