/**
=========================================================
* Dayalbagh New System React - v2.0.0
=========================================================

* Product Page: https://www.hurekatek.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Hureka Technologies Inc. (https://www.hurekatek.com)

Coded by www.hurekatek.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Dayalbagh New System React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import SuiButton from "components/SuiButton";

function ProfilesList({ title, profiles }) {
  const renderProfiles = profiles.map(
    ({
      id,
      uid,
      image,
      name,
      description,
      father_name,
      mother_name,
      action_type,
      action_route,
      action_color,
      action_label,
      action_type_d,
      action_route_d,
      action_color_d,
      action_label_d,
    }) => (
      <SuiBox key={name} component="li" display="flex" alignItems="center" py={1} mb={1}>
        <SuiBox mr={2}>
          <SuiAvatar src={image} alt="something here" variant="rounded" boxShadow="regular" />
        </SuiBox>
        <SuiBox mr={2}>
          <SuiTypography variant="button" fontWeight="medium">
            {id}
          </SuiTypography>
        </SuiBox>
        <SuiBox mr={2}>
          <SuiTypography variant="button" fontWeight="medium">
            {uid}
          </SuiTypography>
        </SuiBox>
        <SuiBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <SuiTypography variant="button" fontWeight="medium">
            {name}
          </SuiTypography>
          <SuiTypography variant="caption" textColor="text">
            {description}
          </SuiTypography>
        </SuiBox>
        <SuiBox ml="auto">
          <SuiTypography variant="caption" textColor="text">
            {father_name}
          </SuiTypography>
        </SuiBox>
        <SuiBox ml="auto">
          <SuiTypography variant="caption" textColor="text">
            {mother_name}
          </SuiTypography>
        </SuiBox>
        <SuiBox ml="auto">
          {action_type === "internal" || action_type_d === "internal" ? (
            <>
              <SuiButton component={Link} to={action_route} variant="text" buttonColor="info">
                {action_label}
              </SuiButton>
              <SuiButton component={Link} to={action_route_d} variant="text" buttonColor="error">
                {action_label_d}
              </SuiButton>
            </>
          ) : (
            <>
              <SuiButton
                component="a"
                href={action_route}
                target="_blank"
                rel="noreferrer"
                variant="text"
                buttonColor={action_color}
              >
                {action_label}
              </SuiButton>
              <SuiButton
                component="a"
                href={action_route_d}
                target="_blank"
                rel="noreferrer"
                variant="text"
                buttonColor={action_color_d}
              >
                {action_label}
              </SuiButton>
            </>
          )}
        </SuiBox>
      </SuiBox>
    )
  );

  return (
    <Card className="h-100">
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SuiTypography>
      </SuiBox>
      <SuiBox p={2}>
        <SuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <SuiBox key="header" component="li" display="flex" alignItems="left" py={1} mb={1}>
            <SuiBox mr={2}>Image</SuiBox>
            <SuiBox ml="auto">
              <SuiTypography variant="caption" fontWeight="medium">
                ID
              </SuiTypography>
            </SuiBox>
            <SuiBox ml="auto">
              <SuiTypography variant="caption" fontWeight="medium">
                UID
              </SuiTypography>
            </SuiBox>
            <SuiBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <SuiTypography variant="button" fontWeight="medium">
                Name
              </SuiTypography>
              <SuiTypography variant="caption" textColor="text">
                email
              </SuiTypography>
            </SuiBox>
            <SuiBox ml="auto">
              <SuiTypography variant="caption" fontWeight="medium">
                Father Name
              </SuiTypography>
            </SuiBox>
            <SuiBox ml="auto">
              <SuiTypography variant="caption" fontWeight="medium">
                Mother Name
              </SuiTypography>
            </SuiBox>
            <SuiBox ml="auto">
              <SuiTypography variant="caption" fontWeight="medium">
                Action
              </SuiTypography>
            </SuiBox>
            <SuiBox ml="auto">
              <SuiTypography variant="caption" fontWeight="medium">
                --
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          {renderProfiles}
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProfilesList;
