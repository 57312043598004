// @mui material components
import { Grid } from "@material-ui/core";
import JigyasuApi from "api/jigyasu";
import React, { useState, useEffect } from "react";
import LabelForValue from "./LabelForValue";
import LabelForQualification from "./LabelForQualification";
import LabelForOccupation from "./LabelForOccupation";

function OrdersOverview(props) {
  const [clickButton, setClickButton] = useState("");
  const [clickButtonlog, setClickButtonlog] = useState([]);
  useEffect(async () => {
    const fetchData = async () => {
      let responceall = await JigyasuApi.GetJigyasuData(props.recordForEdit);
      let responce = responceall["data"] ? responceall["data"]["data"]["0"] : [];
      let responcelog = responceall["data"] ? responceall["data"]["log"] : [];
      setClickButton(responce);
      setClickButtonlog(responcelog);
      return () => {
        responce();
      };
    };
    fetchData();
  }, []);
  const originalDOB = clickButton?.dob; // Assuming clickButton.dob is in a different format
  const dobDate = new Date(originalDOB);
  const formattedDOB = dobDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
  return (
    <React.Fragment>
      <div className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <b>UID: </b> {clickButton.uid ? clickButton.uid : "Not Genrated Yet"}
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-column align-items-center text-center">
                  <Grid item xs={12} sm={6}>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_SERVER_URL}${clickButton.photo}`}
                      alt="No Image"
                      className="rounded-circle"
                      width="150"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="mt-3">
                      <h4>{`${clickButton.first_name} ${clickButton.middle_name} ${clickButton.last_name}`}</h4>
                      <p className="text-secondary mb-1">{`${clickButton.email}`}</p>
                      <p className="text-muted font-size-sm">{`${clickButton.phone}`}</p>
                    </div>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="card mb-3">
              <Grid container spacing={3} stype={{ padding: "20px" }}>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Full Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.first_name} ${clickButton.middle_name} ${clickButton.last_name}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">DOB</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${formattedDOB}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Father Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.father_name}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Father UID</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.father_uid}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Mother Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.mother_name}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Mother UID</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.mother_uid}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Spouses Name</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.spouse_name}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Spouses UID</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.spouse_uid}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Address</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.address1}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Address Line 2</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.address2}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">City</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.city}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">State</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.region}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Country</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.country}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Zip Code/Pin Code</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.zipcode}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Phone/Mobile</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.phone}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Organization</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.organization}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Occupation</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    <LabelForOccupation value={clickButton.occupation} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Qualification</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    <LabelForQualification value={clickButton.qualification} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Designation</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.designation}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Nationality</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">
                    <LabelForValue value={clickButton.nationality} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Caste</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.caste}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Blood Group</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.bloodgroup}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Photo</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.photo}`}</div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="col-sm-3">
                    <h6 className="mb-0">Branch</h6>
                  </div>
                  <div className="col-sm-9 text-secondary">{`${clickButton.branch}`}</div>
                </Grid>
              </Grid>
            </div>
            <Grid item xs={12} sm={12}>
              <h2 className="mb-0">Logs</h2>
              {clickButtonlog.map((log) => {
                return (
                  <div key={log.id}>
                    <h6 className="mb-0">Name</h6>
                    {log?.validate_by ? log?.validate_by : ""}
                    {log?.approved_by ? log?.approved_by : ""}
                    <div className="col-sm-9 text-secondary">
                      <h6 className="mb-0">Note:</h6>
                      {log?.note} (
                      {log?.creation_date
                        ? new Date(log.creation_date).toLocaleDateString("en-US")
                        : ""}
                      )
                    </div>
                    <hr />
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default OrdersOverview;
