/**
=========================================================
* Dayalbagh New System React - v2.0.0
=========================================================

* Product Page: https://www.hurekatek.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Hureka Technologies Inc. (https://www.hurekatek.com)

Coded by www.hurekatek.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import AuthApi from "api/auth";
import { useAuth } from "auth-context/auth.context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// @mui material components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Dayalbagh New System React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";

// Dayalbagh New System React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/NotificationItem";

// Custom styles for DashboardNavbar
import styles from "examples/Navbars/DashboardNavbar/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// Dayalbagh New System React context
import { useSoftUIController } from "context";
// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import Popup from "components/Popup";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const classes = styles({ transparentNavbar, absolute, light, isMini });
  const route = useLocation().pathname.split("/").slice(1);
  const isMinix = false;
  const sessionDuration = 3500; // 1 hour in seconds
  const warningDuration = 600; // 10 minutes in seconds
  const [seconds, setSeconds] = useState(() => {
    const savedTime = sessionStorage.getItem("remainingTime");
    return savedTime ? parseInt(savedTime, 10) : sessionDuration;
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const { setUser } = useAuth();
  let { user } = useAuth();
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        const newTime = prevSeconds - 1;
        sessionStorage.setItem("remainingTime", newTime);
        return newTime;
      });
    }, 1000);

    // Open modal when remaining time is equal to warningDuration
    if (seconds === warningDuration) {
      setIsModalOpen(true);
    }

    // Auto logout when time is up
    if (seconds <= 0) {
      clearInterval(interval);
      logout();
    }

    return () => clearInterval(interval);
  }, [seconds]);

  const extendSession = () => {
    const newTime = sessionDuration;
    setSeconds(newTime);
    sessionStorage.setItem("remainingTime", newTime);
    setIsModalOpen(false);
  };

  const logout = async () => {
    await AuthApi.Logout(user);
    await setUser(null);
    setIsModalOpen(false);
    sessionStorage.removeItem("remainingTime");
    alert("Session expired. Logging out...");
    sessionStorage.removeItem("user");
    return history.push("/authentication/sign-in");
    // Perform logout logic here (e.g., redirect to login page)
  };

  const formatTime = (secs) => {
    const hours = Math.floor(secs / 3600);
    const minutes = Math.floor((secs % 3600) / 60);
    const seconds = secs % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      dispatch({
        type: "TRANSPARENT_NAVBAR",
        value: (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      });
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => dispatch({ type: "MINI_SIDENAV", value: !miniSidenav });
  const handleConfiguratorOpen = () =>
    dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      style={{ marginTop: "1rem" }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="secondary"
        image={
          <Icon fontSize="small" className="material-icon-round text-white">
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );
  const symLayouttitlebar = {
    backgroundColor: "#CCCCFF",
    border: "1px solid #DFDFDF",
    padding: "20px",
    marginBottom: "10px",
  };
  const theme = createTheme();

  theme.typography.h1 = {
    fontSize: "1.2rem !important",
    fontWeight: 300,
    opacity: 1,
    color: "#000",
    lineHeight: "2 !important",
    "@media (min-width:600px)": {
      fontSize: "2.5rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem !important",
    },
  };
  theme.typography.h2 = {
    fontSize: "1rem !important",
    fontWeight: 300,
    opacity: 1,
    color: "#000",
    "@media (min-width:600px)": {
      fontSize: "1.25rem !important",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.25rem !important",
    },
  };
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      className={classes.navbar}
    >
      <div style={symLayouttitlebar}>
        <table>
          <tbody>
            <tr>
              <td>
                <img src="//forma.dayalbagh.org.in/web/images/symLayout/logo.gif" alt="symLayout" />
              </td>
              <td width="100%" align="right">
                <p></p>
                <ThemeProvider theme={theme}>
                  <Typography variant="h1">Radhasoami Satsang Sabha</Typography>
                </ThemeProvider>
                <p></p>
                <ThemeProvider theme={theme}>
                  <Typography variant="h2">ELECTRONIC FORM-A</Typography>
                </ThemeProvider>
                <ThemeProvider theme={theme}>
                  <Typography variant="p">Time remaining: {formatTime(seconds)}</Typography>
                </ThemeProvider>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Toolbar className={classes.navbar_container}>
        <SuiBox customClass={classes.navbar_row} color="inherit" mb={{ xs: 1, md: 0 }}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SuiBox>
        {isMinix ? null : (
          <SuiBox customClass={classes.navbar_row}>
            <SuiBox pr={1}>
              <SuiInput
                placeholder="Type here..."
                withIcon={{ icon: "search", direction: "left" }}
                customClass={(classes.navbar_input, classes.display_h)}
              />
            </SuiBox>
            <SuiBox
              color={light ? "white" : "inherit"}
              customClass={classes.navbar_section_desktop}
            >
              <IconButton
                size="large"
                color="inherit"
                className={classes.navbar_mobile_menu}
                onClick={handleMiniSidenav}
              >
                <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
              <IconButton
                color="inherit"
                className={(classes.navbar_icon_button, classes.display_h)}
                onClick={handleConfiguratorOpen}
              >
                <Icon>settings</Icon>
              </IconButton>
              <IconButton
                color="inherit"
                className={(classes.navbar_icon_button, classes.display_h)}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon>notifications</Icon>
              </IconButton>
              {renderMenu()}
            </SuiBox>
          </SuiBox>
        )}
        <SuiBox>
          <Popup
            className="Modal"
            isOpen={isModalOpen}
            onRequestClose={logout}
            title="Jigyasu Data"
            openPopup={isModalOpen}
            setOpenPopup={setIsModalOpen}
            contentLabel="Session Timeout Warning"
          >
            <h2>Session Timeout Warning</h2>
            <p>Your session will expire soon. Would you like to extend your session?</p>
            <button onClick={extendSession}>Extend Session</button>
            <button onClick={logout}>Logout</button>
          </Popup>
        </SuiBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
