import React from "react";

const nationalities = [
  {
    value: "IN",
    label: "Indian",
  },
  {
    value: "DC",
    label: "Dual Citizen: India + Other",
  },
  {
    value: "FC",
    label: "Other Nationality",
  },
];

const LabelForValue = ({ value }) => {
  // Find the nationality object with the matching value
  const nationality = nationalities.find((nat) => nat.value === value);

  // If the nationality with the given value is found, return its label
  // Otherwise, return a default message
  const label = nationality ? nationality.label : "Unknown Nationality";

  return <span>{label}</span>;
};

export default LabelForValue;
