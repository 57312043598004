import Axios from "axios";
import { API_SERVER } from "../config/constant";
let us = localStorage.getItem("user");
let userside = JSON.parse(us);
let token = userside ? userside["token"] : "Authorization";

const axios = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: { "Content-Type": "application/json", Authorization: `${token}` },
});

axios.interceptors.request.use(
  (config) => {
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
