import checkoutFormModel from "./checkoutFormModel";
const {
  formField: {
    firstName,
    lastName,
    middleName,
    email,
    phone,
    qualification,
    occupation,
    designation,
    bloodGroup,
    phone2,
    branch,
    uid,
    nationality,
    dob,
    father_uid,
    mother_uid,
    spouse_uid,
    fatherName,
    motherName,
    spouse_name,
    address1,
    address2,
    city,
    zipcode,
    country,
    region,
    caste,
    bySecyVerification,
    status,
    revision,
    note,
    validate_by,
    approved_by,
    create_by,
    organization,
    workingHours,
    house,
    vehicle,
    photo,
    title,
    titlef,
    titlem,
    titles,
  },
} = checkoutFormModel;

export default {
  [title.name]: "",
  [titlef.name]: "",
  [titlem.name]: "",
  [titles.name]: "",
  [firstName.name]: "",
  [lastName.name]: "",
  [middleName.name]: "",
  [email.name]: "",
  [phone.name]: "",
  [phone2.name]: "",
  [qualification.name]: "",
  [organization.name]: "",
  [occupation.name]: "",
  [designation.name]: "",
  [nationality.name]: "",
  [branch.name]: "",
  [uid.name]: "",
  [dob.name]: "",
  [father_uid.name]: "",
  [mother_uid.name]: "",
  [spouse_uid.name]: "",
  [fatherName.name]: "",
  [motherName.name]: "",
  [spouse_name.name]: "",
  [address1.name]: "",
  [bloodGroup.name]: "",
  [address2.name]: "",
  [city.name]: "",
  [zipcode.name]: "",
  [country.name]: "IN",
  [region.name]: "",
  [caste.name]: "",
  [status.name]: "",
  [bySecyVerification.name]: false,
  [house.name]: "",
  [photo.name]: "",
  [revision.name]: "",
  [note.name]: "",
  [validate_by.name]: "",
  [approved_by.name]: "",
  [create_by.name]: "",
  [workingHours.name]: "",
  [vehicle.name]: "",
};
