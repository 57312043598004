/**
=========================================================
* Dayalbagh New System React - v2.0.0
=========================================================

* Product Page: https://www.hurekatek.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Hureka Technologies Inc. (https://www.hurekatek.com)

Coded by www.hurekatek.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Dayalbagh New System PRO React base styles
import colors from "assets/theme/base/colors";

const { dark } = colors;

export default {
  styleOverrides: {
    root: {
      color: dark.main,
    },
  },
};
