import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { InputField, DatePickerField, SelectField } from "../../FormFields";
import { FormHelperText } from "@mui/material";
import JigyasuApi from "api/jigyasu";

const title_data = [
  {
    value: "MR",
    label: "MR",
  },
  {
    value: "MS",
    label: "MS",
  },
];

export default function FirstForm(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [branchList, setBranchList] = useState({});
  useEffect(async () => {
    let responce = await JigyasuApi.BranchCountAPI();
    setBranchList(responce?.data);
  }, []);
  const {
    formField: { title, firstName, lastName, middleName, dob, branch },
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Initial Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <SelectField
            name={branch.name}
            label={branch.label}
            data={branchList.branch_list}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <SelectField name={title.name} label={title.label} data={title_data} fullWidth />
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputField name={firstName.name} label={firstName.label} fullWidth />
          <FormHelperText>
            Mention name and date of birth as per AADHAAR / PAN Card / Voter ID / Passport / Class
            10th School Leaving Certificate / Birth Certificate.
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={5}>
          <InputField name={middleName.name} label={middleName.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={lastName.name} label={lastName.label} fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePickerField
            name={dob.name}
            label={dob.label}
            format="dd/MM/YYY"
            views={["year", "month", "date"]}
            maxDate={new Date()}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            autoComplete="off"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            fullWidth
          />
        </Grid>
        <FormHelperText>
          <strong>
            Before proceeding to fill the Jigyasu Registration Form, Branch Secretary/Centre
            In-charge must read the Guidelines carefully, reproduced below for ready reference:
          </strong>
          <br />
          <br />
          1. Any person desirous of becoming a follower of Ra Dha Sva Aa Mi Faith and who is not
          less than 22 years of age may register himself as a Jigyasu at a Branch Satang/Satsang
          Centre, recognized by Radhasoami Satsang Sabha, Dayalbagh, Agra, where it is convenient
          for him/her to attend Satsang regularly. The Branch Secretary/Centre In-charge should
          maintain a register of Jigyasus in which their particulars and attendance may be recorded.
          <br />
          <br />
          2. Those children who have completed the age of 18 years and whose parents (both) are
          initiated Satsangis of at least 3 years standing, can get their names registered as
          jigyasus. As regards others , the minimum age for registration as jigyasus shall remain
          the same i.e.22 years only.
          <br />
          <br />
          <strong>
            जिज्ञासु पंजीकरण फॉर्म भरने से पहले, ब्रांच सेक्रेटरी/सेंटर इन-चार्ज निम्मलिखित
            दिशानिर्देशों को संधर्भ हेतु ध्यानपूर्वक पढ़ें:
          </strong>
          <br />
          <br />
          1. कोई भी व्यक्ति जो रा धा/ध: स्व आ मी मत का अनुयायी बनना चाहता है और जिसकी आयु 22 वर्ष से
          कम नहीं है, वह राधास्वामी सत्संग सभा, दयालबाग, आगरा द्वारा मान्यता प्राप्त ब्रांच
          सत्संग/सत्संग केंद्र, जहां उसके लिए नियमित रूप से शामिल होना सुविधाजनक हो , में जिज्ञासु
          के रूप में अपना पंजीकरण करा सकता है। ब्रांच सेक्रेटरी/सेंटर इन-चार्ज को चाहिए कि वह
          जिज्ञासुओं का एक पंजीकरण रजिस्टर रखें जिसमें उनका विवरण और उपस्थिति दर्ज की जा सके |
          <br />
          <br />
          2. वह बच्चे जो 18 वर्ष की आयु पूर्ण कर चुके हैं और जिनके माता-पिता (दोनों) कम से कम 3 वर्ष
          से उपदेश प्राप्त सत्संगी हैं, वे अपना नाम जिज्ञासु के रूप में पंजीकृत करा सकते हैं। अन्य
          के लिए जिज्ञासु के रूप में पंजीकरण के लिए न्यूनतम आयु 22 वर्ष ही रहेगी।
          <br />
          <br />
        </FormHelperText>
      </Grid>
    </React.Fragment>
  );
}
