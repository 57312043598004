import React, { useState } from "react";
import { Stepper, Step, StepLabel, Button, Typography, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";

import FirstForm from "components/CheckoutPage/Forms/FirstForm";
import SecondForm from "components/CheckoutPage/Forms/SecondForm";
import ThirdForm from "components/CheckoutPage/Forms/ThirdForm";
import FourthForm from "components/CheckoutPage/Forms/FourthForm";
import ReviewOrder from "components/CheckoutPage/ReviewOrder";
import CheckoutSuccess from "components/CheckoutPage/CheckoutSuccess";

import validationSchema from "components/CheckoutPage/FormModel/validationSchema";
import checkoutFormModel from "components/CheckoutPage/FormModel/checkoutFormModel";
import formInitialValues from "components/CheckoutPage/FormModel/formInitialValues";

import useStyles from "components/CheckoutPage/styles";
// Dayalbagh New System React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import FifthForm from "components/CheckoutPage/Forms/FifthForm";
import JigyasuApi from "api/jigyasu";

const steps = [
  "Initial Details",
  "Parent UID Details",
  "Parent UID Details",
  "Applicant Detail",
  "Applicant Detail 2",
  "Review Details",
];
const { formId, formField } = checkoutFormModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <FirstForm formField={formField} />;
    case 1:
      return <SecondForm formField={formField} />;
    case 2:
      return <ThirdForm formField={formField} />;
    case 3:
      return <FourthForm formField={formField} />;
    case 4:
      return <FifthForm formField={formField} />;
    case 5:
      return <ReviewOrder />;
    default:
      return <div>Your Data is saved in Draft</div>;
  }
}

export default function CheckoutPage() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [clickButton, setClickButton] = useState("main");
  const isLastStep = activeStep === steps.length - 1;
  const currentValidationSchema = validationSchema[activeStep];
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  /*function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }*/

  async function _submitForm(values, actions) {
    try {
      let usersend = localStorage.getItem("user");
      usersend = JSON.parse(usersend);
      actions.setSubmitting(false);
      var data = values;
      data.users = usersend;
      data.nee = "";
      data.status = 1;
      let response = await JigyasuApi.Create({ data });
      if (response.data && response.data.success === false) {
        return "setError(response.data.msg)";
      }
      alert("Data has been submitted");
      actions.setSubmitting(false);
      setActiveStep(activeStep + 1);
      return history.push("/");
    } catch (err) {
      console.log(err);
      if (err.response) {
        return "setError(err.response.data.msg);";
      }
      return "setError('There has been an error.')";
    } finally {
      setIsButtonDisabled(false);
    }
    //await _sleep(1000);
    //alert(JSON.stringify(values, null, 2));
    //actions.setSubmitting(false);
    //setActiveStep(activeStep + 1);
  }

  async function _draftForm(values, actions) {
    try {
      let usersend = localStorage.getItem("user");
      usersend = JSON.parse(usersend);
      actions.setSubmitting(false);
      var data = values;
      data.users = usersend;
      data.nee = "";
      data.status = 7;
      let response = await JigyasuApi.Create({ data });
      if (response.data && response.data.success === false) {
        return "setError(response.data.msg)";
      }
      alert("Draft has been saved.");
      //actions.setSubmitting(false);
      setActiveStep(activeStep + 1);
      return history.push("/");
    } catch (err) {
      console.log(err);
      if (err.response) {
        return "setError(err.response.data.msg);";
      }
      return "setError('There has been an error.')";
    } finally {
      setIsButtonDisabled(false);
    }
    //await _sleep(1000);
    //alert(JSON.stringify(values, null, 2));
    //actions.setSubmitting(false);
    //setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    setIsButtonDisabled(true);
    if (isLastStep) {
      if (clickButton == "draft") {
        _draftForm(values, actions);
      } else {
        _submitForm(values, actions);
      }
    } else {
      if (activeStep === 0) {
        if (calculateAge(new Date(values.dob)) >= 18 && calculateAge(new Date(values.dob)) <= 22) {
          setActiveStep(activeStep + 1);
        } else {
          setActiveStep(activeStep + 2);
        }
      } else if (activeStep === 1) {
        setActiveStep(activeStep + 2);
      } else {
        setActiveStep(activeStep + 1);
      }
      actions.setTouched({});
      actions.setSubmitting(false);
      setIsButtonDisabled(false);
    }
  }

  function _handleBack() {
    if (activeStep === 2) {
      setActiveStep(activeStep - 2);
    } else {
      setActiveStep(activeStep - 1);
    }
  }

  function _setActiveButton() {
    setClickButton("draft");
  }

  function _setActiveMain() {
    setClickButton("main");
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <React.Fragment>
        <Typography component="h1" variant="h4" align="center">
          Jigyasu Form
        </Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            clickButton === "draft" ? (
              "Your Data is saved in Draft"
            ) : (
              <CheckoutSuccess />
            )
          ) : (
            <Formik
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form id={formId}>
                  {_renderStepContent(activeStep)}

                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={_handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <div className={classes.wrapper}>
                      <Button
                        disabled={isSubmitting || isButtonDisabled}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => _setActiveMain()}
                        className={classes.button}
                      >
                        {isLastStep ? "Submit" : "Next"}
                      </Button>
                      {isLastStep ? (
                        <Button
                          disabled={isSubmitting || isButtonDisabled}
                          onClick={() => _setActiveButton()}
                          className={classes.button}
                          type="submit"
                        >
                          Draft
                        </Button>
                      ) : (
                        ""
                      )}
                      {isSubmitting && (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </React.Fragment>
      </React.Fragment>
      <Footer />
    </DashboardLayout>
  );
}

function calculateAge(birthday) {
  var ageDifMs = birthday.getTime();
  var years = 0;
  var ageRestriction = new Date().setFullYear(new Date().getFullYear() - 18);
  var ageRestrictionnext = new Date().setFullYear(new Date().getFullYear() - 22);
  if (ageDifMs < ageRestriction && ageDifMs > ageRestrictionnext) {
    years = 18;
  } else {
    years = 0;
  }
  console.log(years);
  return Math.abs(years);
}
