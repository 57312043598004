import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { InputField, CheckboxField, SelectField } from "../../FormFields";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Typography, Button, withStyles } from "@material-ui/core";
import uploadFilesService from "components/services/upload-files.service";
import { useFormikContext } from "formik";
import { FormHelperText } from "@mui/material";

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const blood = [
  {
    value: "A+",
    label: "A+",
  },
  {
    value: "A-",
    label: "A-",
  },
  {
    value: "B+",
    label: "B+",
  },
  {
    value: "B-",
    label: "B-",
  },
  {
    value: "O+",
    label: "O-",
  },
  {
    value: "O+",
    label: "O+",
  },
  {
    value: "AB+",
    label: "AB+",
  },
  {
    value: "AB-",
    label: "AB-",
  },
];

export default function FifthForm(props) {
  const {
    formField: { designation, organization, bloodGroup, photo, bySecyVerification },
  } = props;
  const { setFieldValue } = useFormikContext(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [imageInfos, setImageInfos] = useState([]);
  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
    setProgress(0);
    setMessage("");
  };
  const upload = () => {
    setProgress(0);
    uploadFilesService
      .upload(currentFile, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      })
      .then((response) => {
        setMessage(response.data.message);
        setIsError(false);
        setImageInfos(response.data);
        setFieldValue("photo", response.data.image);
      })
      .catch(() => {
        setProgress(0);
        setMessage("Could not upload the image!");
        setCurrentFile(undefined);
        setIsError(true);
      });
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Applicant Details (Contd).
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField name={designation.name} label={designation.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={organization.name} label={organization.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField name={bloodGroup.name} label={bloodGroup.label} data={blood} fullWidth />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <SelectField
            name={workingHours.name}
            label={workingHours.label}
            data={workinghoursl}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField name={house.name} label={house.label} data={houses} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField name={vehicle.name} label={vehicle.label} data={vehicles} fullWidth />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <InputField
            name={photo.name}
            label={photo.label}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
          <Grid item xs={12} sm={6}>
            <label htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={selectFile}
              />
              <Button className="btn-choose" variant="outlined" component="span">
                Choose Image
              </Button>
            </label>
            <div className="file-name">{currentFile ? currentFile.name : null}</div>
            <Button
              className="btn-upload"
              color="primary"
              variant="contained"
              component="span"
              disabled={!currentFile}
              onClick={upload}
            >
              Upload
            </Button>

            {currentFile && (
              <Box className="my20" display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <BorderLinearProgress variant="determinate" value={progress} />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                </Box>
              </Box>
            )}
            {previewImage && (
              <div>
                <img
                  className="preview my20"
                  style={{ width: "500px" }}
                  src={previewImage}
                  alt=""
                />
              </div>
            )}

            {message && (
              <Typography
                variant="subtitle2"
                className={`upload-message ${isError ? "error" : ""}`}
              >
                {imageInfos.url}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CheckboxField name={bySecyVerification.name} label={bySecyVerification.label} />
          <br />
          ब्रांच सेक्रेटरी/सेंटर इन-चार्ज द्वारा सत्यापन प्रमाणपत्र *
          <FormHelperText>
            <strong>
              I hereby certify that the Name and Date of Birth of the applicant has been verified
              from below mentioned documents (any one of the below):
              <br />
              <br />
              मैं एतद्द्वारा प्रमाणित करता हूं कि आवेदक का नाम और जन्मतिथि निम्मलिखित दस्तावेज़ों
              में से (किसी एक से) सत्यापित किये गए हैं:
              <br />
              <br />
            </strong>
            • AADHAAR / आधार
            <br />• PAN / पैन
            <br />• Voter ID / वोटर आई डी
            <br />• Passport / पासपोर्ट
            <br />• Class 10th School Leaving Certificate / दसवीं कक्षा के स्कूल छोड़ने का
            प्रमाणपत्र
            <br />• Birth Certificate / जन्म प्रमाणपत्र
          </FormHelperText>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
