/**
=========================================================
* Dayalbagh New System React - v2.0.0
=========================================================

* Product Page: https://www.hurekatek.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Hureka Technologies Inc. (https://www.hurekatek.com)

Coded by www.hurekatek.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base border styles for the Dayalbagh New System PRO Material.
 * You can add new border width, border color or border radius using this file.
 * You can customized the borders value for the entire Dayalbagh New System PRO Material using thie file.
 */

// Dayalbagh New System PRO React Base Styles
import colors from "assets/theme/base/colors";

// Dayalbagh New System PRO React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { grey } = colors;

export default {
  borderColor: grey[300],

  borderWidth: {
    0: 0,
    1: pxToRem(1),
    2: pxToRem(2),
    3: pxToRem(3),
    4: pxToRem(4),
    5: pxToRem(5),
  },

  borderRadius: {
    xs: pxToRem(2),
    sm: pxToRem(4),
    md: pxToRem(8),
    lg: pxToRem(12),
    xl: pxToRem(16),
    xxl: pxToRem(24),
    section: pxToRem(160),
  },
};
