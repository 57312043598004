/**
=========================================================
* Dayalbagh New System React - v2.0.0
=========================================================

* Product Page: https://www.hurekatek.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Hureka Technologies Inc. (https://www.hurekatek.com)

Coded by www.hurekatek.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
//import Icon from "@mui/material/Icon";

// Dayalbagh New System React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Dayalbagh New System React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import JigyasuApi from "api/jigyasu";
import React, { useState, useEffect } from "react";
import { useAuth } from "auth-context/auth.context";
import { useHistory } from "react-router-dom";
//import rocketWhite from "assets/images/illustrations/rocket-white.png";

function Dashboarduv() {
  //const classes = styles();
  const [countdata, setData] = useState([]);
  let { user } = useAuth();
  if (user.role == "BBRANCHSEC") {
    const history = useHistory();
    history.push("/dashboard");
  }
  useEffect(async () => {
    let responce = await JigyasuApi.GetJigyasuUVDataCount();
    console.log("ddddd", responce);
    setData(responce?.data);
  }, []);
  let urlp = "/uv/jigyasu/list";
  let urlv = "/uv/jigyasu/validated";
  let urla = "/uv/jigyasu/approved";
  let urlr = "/uv/jigyasu/rejected";
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Card>
                <SuiBox p={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} xl={12}>
                      <SuiTypography variant="h2" mb={2} fontWeight="bold" gutterBottom>
                        Jigyasu Registration
                      </SuiTypography>
                    </Grid>
                    {user.role == "DATAVIEWER" && (
                      <Grid item xs={12} sm={3} xl={3}>
                        <Link href={urlp} key="jigyasu-form-link" rel="noreferrer">
                          <MiniStatisticsCard
                            title={{ text: "In Progress", textColor: "black" }}
                            count={countdata?.pending ? countdata?.pending : 0}
                            percentage={{ color: "success", text: "" }}
                            icon={{ color: "info", component: "public", iconOrder: "left" }}
                            backgroundColor="primary"
                            direction="left"
                          />
                        </Link>
                      </Grid>
                    )}
                    {user.role != "DATAVIEWER" && (
                      <>
                        <Grid item xs={12} sm={3} xl={3}>
                          <Link href={urlp} key="jigyasu-form-link" rel="noreferrer">
                            <MiniStatisticsCard
                              title={{ text: "Pending Validation", textColor: "black" }}
                              count={countdata?.pending ? countdata?.pending : 0}
                              percentage={{ color: "success", text: "" }}
                              icon={{ color: "info", component: "public", iconOrder: "left" }}
                              backgroundColor="primary"
                              direction="left"
                            />
                          </Link>
                        </Grid>
                        <Grid item xs={12} sm={3} xl={3}>
                          <Link href={urlv} key="jigyasu-form-link" rel="noreferrer">
                            <MiniStatisticsCard
                              title={{ text: "Pending Approval" }}
                              count={countdata?.validate ? countdata?.validate : 0}
                              percentage={{ color: "success", text: "" }}
                              icon={{ color: "info", component: "public", iconOrder: "left" }}
                              backgroundColor="info"
                              direction="left"
                            />
                          </Link>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={3} xl={3}>
                      <Link href={urla} key="jigyasu-form-link" rel="noreferrer">
                        <MiniStatisticsCard
                          title={{ text: "Approved" }}
                          count={countdata?.approved ? countdata?.approved : 0}
                          percentage={{ color: "success", text: "" }}
                          icon={{ color: "info", component: "public", iconOrder: "left" }}
                          backgroundColor="success"
                          direction="left"
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <Link href={urlr} key="jigyasu-form-link" rel="noreferrer">
                        <MiniStatisticsCard
                          title={{ text: "Returned for correction" }}
                          count={countdata?.approved ? countdata?.rejected : 0}
                          percentage={{ color: "success", text: "" }}
                          icon={{ color: "info", component: "public", iconOrder: "left" }}
                          backgroundColor="warning"
                          direction="left"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </SuiBox>
              </Card>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboarduv;
