import React, { useState, useEffect } from "react";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";

import validationSchemaUV from "components/CheckoutPage/FormModel/validationSchemaUV";
import checkoutFormModel from "components/CheckoutPage/FormModel/checkoutFormModel";
import formInitialValuesUV from "components/CheckoutPage/FormModel/formInitialValuesUV";
import UveditForm from "components/CheckoutPage/Forms/uveditForm";

import useStyles from "components/CheckoutPage/styles";
// Dayalbagh New System React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import JigyasuApi from "api/jigyasu";
import { useParams } from "react-router-dom";
import { useAuth } from "auth-context/auth.context";

const { formId, formField } = checkoutFormModel;

function _renderStepContent(step, datanew) {
  switch (step) {
    case 0:
      return <UveditForm formField={formField} formData={datanew} />;
    default:
      return <div>Your Data is saved in Draft</div>;
  }
}

export default function BcResponseForm() {
  const classes = useStyles();
  const [activeStep] = useState(0);
  const [clickButton, setClickButton] = useState("main");
  const currentValidationSchema = validationSchemaUV[activeStep];
  const formInitialValuesUVdata = formInitialValuesUV;
  const params = useParams();
  let { user } = useAuth();
  let title = "";
  let titleb = "";
  if (user.role == "DBAPPROVER" || user.role == "UVVOLUNTER") {
    if (user.role == "DBAPPROVER") {
      title = "Approve";
      titleb = "Return to Branch Secretary";
    } else {
      title = "Validated";
      titleb = "Return to Branch Secretary";
    }
  } else if (user.role == "BBRANCHSEC") {
    title = "Resubmit";
    titleb = "Cancel";
  }

  useEffect(async () => {
    let responceall = await JigyasuApi.GetJigyasuData(params.id);
    let responce = responceall["data"]["data"] ? responceall["data"]["data"]["0"] : [];
    formInitialValuesUVdata.title = responce.title ? responce.title : "";
    formInitialValuesUVdata.firstName = responce.first_name ? responce.first_name : "";
    formInitialValuesUVdata.lastName = responce.last_name ? responce.last_name : "";
    formInitialValuesUVdata.middleName = responce.middle_name ? responce.middle_name : "";
    formInitialValuesUVdata.email = responce.email ? responce.email : "";
    formInitialValuesUVdata.phone = responce.phone ? responce.phone : "";
    formInitialValuesUVdata.titlef = responce.titlef ? responce.titlef : "";
    formInitialValuesUVdata.titlem = responce.titlem ? responce.titlem : "";
    formInitialValuesUVdata.titles = responce.titles ? responce.titles : "";
    formInitialValuesUVdata.qualification = responce.qualification ? responce.qualification : "";
    formInitialValuesUVdata.organization = responce.organization ? responce.organization : "";
    formInitialValuesUVdata.occupation = responce.occupation ? responce.occupation : "";
    formInitialValuesUVdata.designation = responce.designation ? responce.designation : "";
    formInitialValuesUVdata.nationality = responce.nationality ? responce.nationality : "";
    formInitialValuesUVdata.branch = responce.branch ? responce.branch : "";
    formInitialValuesUVdata.dob = responce.dob ? responce.dob : "";
    formInitialValuesUVdata.father_uid = responce.father_uid ? responce.father_uid : "";
    formInitialValuesUVdata.mother_uid = responce.mother_uid ? responce.mother_uid : "";
    formInitialValuesUVdata.spouse_uid = responce.spouse_uid ? responce.spouse_uid : "";
    formInitialValuesUVdata.fatherName = responce.father_name ? responce.father_name : "";
    formInitialValuesUVdata.motherName = responce.mother_name ? responce.mother_name : "";
    formInitialValuesUVdata.spouse_name = responce.spouse_name ? responce.spouse_name : "";
    formInitialValuesUVdata.address1 = responce.address1 ? responce.address1 : "";
    formInitialValuesUVdata.bloodGroup = responce.bloodgroup ? responce.bloodgroup : "";
    formInitialValuesUVdata.address2 = responce.address2 ? responce.address2 : "";
    formInitialValuesUVdata.city = responce.city ? responce.city : "";
    formInitialValuesUVdata.zipcode = responce.zipcode ? responce.zipcode : "";
    formInitialValuesUVdata.country = responce.country ? responce.country : "";
    formInitialValuesUVdata.region = responce.region ? responce.region : "";
    formInitialValuesUVdata.status = responce.status ? responce.status : "";
    formInitialValuesUVdata.house = responce.house ? responce.house : "";
    formInitialValuesUVdata.photo = responce.photo ? responce.photo : "";
    formInitialValuesUVdata.caste = responce.caste ? responce.caste : "";
    formInitialValuesUVdata.workingHours = responce.workinghours ? responce.workinghours : "";
    formInitialValuesUVdata.note = responce.note ? responce.note : "";
    formInitialValuesUVdata.vehicle = responce.vehicle ? responce.vehicle : "";
  }, []);

  async function _submitForm(values, actions) {
    try {
      actions.setSubmitting(false);
      var data = values;
      data.id = params.id;
      data.nee = "";
      data.status = "6";
      // let response = await JigyasuApi.GetJigyasuDataUpdate({ data });
      //if (response.data && response.data.success === false) {
      //  return "setError(response.data.msg)";
      //}
      if (user.role == "UVVOLUNTER") {
        alert("Request has been validated and sent for approval.");
      } else if (user.role == "DBAPPROVER") {
        alert("Request has been Approved and New Jigyasu ID is :.");
      }
      actions.setSubmitting(false);
      const timeout = setTimeout(() => {
        // 👇️ redirects to an external URL
        window.location.replace("/uv/jigyasu/rejected");
      }, 1000);
      return () => clearTimeout(timeout);
    } catch (err) {
      if (err.response) {
        return "setError(err.response.data.msg);";
      }
      return "setError('There has been an error.')";
    }
  }

  async function _rejectForm(values, actions) {
    try {
      actions.setSubmitting(false);
      var data = values;
      data.id = params.id;
      data.nee = "";
      data.status = "1";
      let response = await JigyasuApi.GetJigyasuDataUpdate({ data });
      if (response.data && response.data.success === false) {
        return "setError(response.data.msg)";
      }
      alert("Request has been Resubmitted to Sabha.");
      //actions.setSubmitting(false);
      actions.setSubmitting(false);
      const timeout = setTimeout(() => {
        // 👇️ redirects to an external URL
        window.location.replace("/uv/jigyasu/rejected");
      }, 500);
      return () => clearTimeout(timeout);
    } catch (err) {
      if (err.response) {
        return "setError(err.response.data.msg);";
      }
      return "setError('There has been an error.')";
    }
  }

  function _handleSubmit(values, actions) {
    if (clickButton == "draft") {
      _submitForm(values, actions);
    } else {
      _rejectForm(values, actions);
    }
  }

  function _setActiveButton() {
    setClickButton("draft");
  }

  function _setActiveMain() {
    setClickButton("main");
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <React.Fragment>
        <Typography component="h1" variant="h4" align="center">
          Jigyasu Form
        </Typography>
        <React.Fragment>
          <Formik
            initialValues={formInitialValuesUV}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(0, formInitialValuesUVdata)}

                <div className={classes.buttons}>
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => _setActiveMain()}
                      className={classes.button}
                    >
                      {title}
                    </Button>
                    <Button
                      onClick={() => _setActiveButton()}
                      className={classes.button}
                      type="submit"
                    >
                      {titleb}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      </React.Fragment>
      <Footer />
    </DashboardLayout>
  );
}

/*function calculateAge(birthday) {
  var ageDifMs = Date.now() - birthday;
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}*/
