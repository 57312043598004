export default {
  formId: "checkoutForm",
  formField: {
    firstName: {
      name: "firstName",
      label: "First name*",
      requiredErrorMsg: "First name is required",
    },
    lastName: {
      name: "lastName",
      label: "Last name",
      requiredErrorMsg: "Last name is required",
    },
    email: {
      name: "email",
      label: "Email Address*",
      requiredErrorMsg: "Email Address is required",
    },
    phone: {
      name: "phone",
      label: "Phone Number*",
      requiredErrorMsg: "Phone Number is required",
    },
    branch: {
      name: "branch",
      label: "Branch/Center*",
      requiredErrorMsg: "Branch Name is required",
    },
    uid: {
      name: "uid",
      label: "UID*",
      requiredErrorMsg: "UID is required",
    },
    status: {
      name: "status",
      label: "Status*",
      requiredErrorMsg: "Status is required",
    },
    revision: {
      name: "revision",
      label: "Revision*",
      requiredErrorMsg: "Revision is required",
    },
    note: {
      name: "note",
      label: "Note*",
      requiredErrorMsg: "Note is required",
    },
    validate_by: {
      name: "validate_by",
      label: "Validate By*",
      requiredErrorMsg: "Validate By is required",
    },
    create_by: {
      name: "create_by",
      label: "Create By*",
      requiredErrorMsg: "Create By is required",
    },
    approved_by: {
      name: "approved_by",
      label: "approved_by*",
      requiredErrorMsg: "approved_by is required",
    },
    phone2: {
      name: "phone",
      label: "Phone Number*",
      requiredErrorMsg: "Phone Number is required",
    },
    caste: {
      name: "caste",
      label: "Caste*",
      requiredErrorMsg: "Caste is required*",
    },
    qualification: {
      name: "qualification",
      label: "Qualification*",
      requiredErrorMsg: "Qualification is required",
    },
    occupation: {
      name: "occupation",
      label: "Occupation*",
      requiredErrorMsg: "Occupation is required",
    },
    nationality: {
      name: "nationality",
      label: "Nationality*",
      requiredErrorMsg: "Nationality is required",
    },
    designation: {
      name: "designation",
      label: "Designation",
      requiredErrorMsg: "Designation is required",
    },
    organization: {
      name: "organization",
      label: "Organization",
      requiredErrorMsg: "Organization is required",
    },
    bloodGroup: {
      name: "bloodGroup",
      label: "Blood Group",
      requiredErrorMsg: "Blood Group is required",
    },
    workingHours: {
      name: "workingHours",
      label: "Working Hours",
      requiredErrorMsg: "Working Hours is required",
    },
    house: {
      name: "house",
      label: "House",
      requiredErrorMsg: "House is required",
    },
    vehicle: {
      name: "vehicle",
      label: "Vehicle",
      requiredErrorMsg: "Vehicle is required",
    },
    photo: {
      name: "photo",
      label: "Attach Photo",
      requiredErrorMsg: "Please Upload photo.",
    },
    bySecyVerification: {
      name: "bySecyVerification*",
      label: "Verification Certificate by Branch Secretary/Centre In-charge.*",
      requiredErrorMsg: "Verification is required",
    },
    middleName: {
      name: "middleName",
      label: "Middle name",
    },
    dob: {
      name: "dob",
      label: "Date of Birth*",
      requiredErrorMsg: "Date of Birth is required",
    },
    father_uid: {
      name: "father_uid",
      label: "Father's UID",
      requiredErrorMsg: "Father's UID is required",
    },
    fatherName: {
      name: "fatherName",
      label: "Father's Name*",
      requiredErrorMsg: "Father's Name is required",
    },
    mother_uid: {
      name: "mother_uid",
      label: "Mother's UID",
      requiredErrorMsg: "Mother's UID is required",
    },
    motherName: {
      name: "motherName",
      label: "Mother's Name*",
      requiredErrorMsg: "Mother's Name is required",
    },
    spouse_uid: {
      name: "spouse_uid",
      label: "Spouse's UID",
      requiredErrorMsg: "Spouse's UID is required",
    },
    spouse_name: {
      name: "spouse_name",
      label: "Spouse's Name",
      requiredErrorMsg: "Spouse's Name is required",
    },
    address1: {
      name: "address1",
      label: "Address Line 1*",
      requiredErrorMsg: "Address Line 1 is required",
    },
    address2: {
      name: "address2",
      label: "Address Line 2",
    },
    city: {
      name: "city",
      label: "City*",
      requiredErrorMsg: "City is required",
    },
    region: {
      name: "region",
      label: "State/Province/Region*",
      requiredErrorMsg: "State/Province/Region is required",
    },
    zipcode: {
      name: "zipcode",
      label: "Pin Code*",
      requiredErrorMsg: "Pin Code is required",
      invalidErrorMsg: "Pin Code is not valid (e.g. 70000)",
    },
    country: {
      name: "country",
      label: "Country*",
      requiredErrorMsg: "Country is required",
    },
    useAddressForPaymentDetails: {
      name: "useAddressForPaymentDetails",
      label: "Use this address for payment details",
    },
    nameOnCard: {
      name: "nameOnCard",
      label: "Name on card*",
      requiredErrorMsg: "Name on card is required",
    },
    cardNumber: {
      name: "cardNumber",
      label: "Card number*",
      requiredErrorMsg: "Card number is required",
      invalidErrorMsg: "Card number is not valid (e.g. 4111111111111)",
    },
    expiryDate: {
      name: "expiryDate",
      label: "Expiry date*",
      requiredErrorMsg: "Expiry date is required",
      invalidErrorMsg: "Expiry date is not valid",
    },
    cvv: {
      name: "cvv",
      label: "CVV*",
      requiredErrorMsg: "CVV is required",
      invalidErrorMsg: "CVV is invalid (e.g. 357)",
    },
    title: {
      name: "title",
      label: "Title*",
      requiredErrorMsg: "Title is required",
      invalidErrorMsg: "Title is invalid (e.g. MR.)",
    },
    titlef: {
      name: "titlef",
      label: "Title*",
      requiredErrorMsg: "Title is required",
      invalidErrorMsg: "Title is invalid (e.g. MR.)",
    },
    titlem: {
      name: "titlem",
      label: "Title*",
      requiredErrorMsg: "Title is required",
      invalidErrorMsg: "Title is invalid (e.g. MR.)",
    },
    titles: {
      name: "titles",
      label: "Title",
      requiredErrorMsg: "Title is required",
      invalidErrorMsg: "Title is invalid (e.g. MR.)",
    },
  },
};
